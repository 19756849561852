const React = require('react')
const { IonButton } = require('@ionic/react')


class Upload extends React.Component {
  constructor(props) {
    super(props)
    let initialImage = null
    try {
      const base64String = btoa(String.fromCharCode(...new Uint8Array(this.props.initialImage.data)));
      initialImage = "data:image/png;base64," + base64String
    } catch (err) {
      console.log("No initial Image")
    }
    this.state = {
      file: initialImage,
      filename: ""
    }
    this.handleChange = this.handleChange.bind(this)
  }


  handleChange(event) {
    this.props.imageSelectCallback(event.target.files[0])
    try {
      this.setState({
        file: URL.createObjectURL(event.target.files[0]),
        filename: event.target.files[0].filename
      })
    } catch (err) {
      this.setState({
        file: null,
        filename: ""
      })
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let image = null
    let filename = ""
    if (nextProps.initialImage == null) {
      return {
        file: null,
        filename: filename
      }
    }
    try {
      const base64String = new Buffer.from(nextProps.initialImage.data).toString("base64")
      image = "data:image/png;base64," + base64String
    } catch (err) {
      image = prevState.file
      filename = prevState.filename
    }
    return {
      file: image,
      filename: filename
    }
  }

  render() {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <input type="file" id="img" name="img" accept="image/*" value={this.state.filename} onChange={this.handleChange} className="input-img" />
        {this.state.file && <img src={this.state.file} width="80" height="80" style={{ marginLeft: "40px" }} alt="serviceLogo" />}
      </div>
    );
  }
}

export default Upload