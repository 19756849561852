import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    IonCard, IonCol, IonContent, IonGrid, IonIcon, IonPage, IonRow, IonText,
    IonButton,
    getPlatforms
} from '@ionic/react';
import { personAddSharp } from 'ionicons/icons';

import { GetABMClients } from '../../store/ABMClients/ABMClientsActions';
import { ABMClientsDefaultStateI } from '../../store/ABMClients/ABMClientsReducer';

import Footer from '../Footer/Footer';
import Header from '../header/Header';
import ABMClientsTable from './ABMClientsTable';
import AddABMClients from './AddABMClients';
import ModifyABMClients from './ModifyABMClients';

import './ABMClients.css';
import Loading from '../Loading/Loading';

interface Data {
    service_name: string;
    service_display_name: string;
    service_maintenance: boolean;
    service_image: any;
    service_created_by: string;
    service_updated_by: string;
    service_category: string;
}

interface Props extends StateTypes, dispatchTypes { }

const ABMClients: React.FC<Props> = (props): JSX.Element => {

    const [renderViews, setRenderViews] = useState<any>({
        showTable: true,
        showCreate: false,
        showModify: false
    })

    const [dataToModyfy, setDataToModify] = useState<Data>({
        service_name: '',
        service_display_name: '',
        service_maintenance: false,
        service_image: '',
        service_created_by: '',
        service_updated_by: '',
        service_category: ''
    })
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
      });
      const [shouldRefresh, setShouldRefresh] = useState(false);
      const desktop = getPlatforms().includes("desktop")

      useEffect(() => {
    
        function handleResize() {
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight
          });
          setShouldRefresh(true);
        }
    
        if(desktop){
          window.addEventListener('resize', handleResize);
          return () => window.removeEventListener('resize', handleResize);
        }
        
      }, []);
    
      useEffect(() => {
        if (shouldRefresh) {
          setShouldRefresh(false);
          window.location.reload();
        }
      }, [shouldRefresh]);

    useEffect(() => {
        props.getABMClients();
    }, []);

    const renderCreate = () => {
        setRenderViews({
            showTable: false,
            showCreate: true,
            showModify: false
        })
    }

    const renderModify = (data: Data) => {
        setRenderViews({
            showTable: false,
            showCreate: false,
            showModify: true
        })
        setDataToModify(data)
    }

    const backToTable = () => {
        setRenderViews({
            showTable: true,
            showCreate: false,
            showModify: false
        })
    }

    return (
        <IonPage>
            <Header headerText='ABM Clientes' />
            {
                renderViews.showCreate ? <AddABMClients backToTable={backToTable} /> :
                    renderViews.showModify ? <ModifyABMClients clientData={dataToModyfy} backToTable={backToTable} /> :
                        renderViews.showTable && props.ABMClients.loading === false && props.ABMClients.abmclients ?
                        <IonContent fullscreen>
                            <IonGrid className='content-abm-client' id="ion-content-main">
                                <IonCard className="ion-padding ABMClientsTable">
                                    <IonRow className="ion-padding justify-abm">
                                        <IonButton className="container-button-add" fill='clear' onClick={renderCreate} >
                                            <IonIcon icon={personAddSharp} color="secondary" size="large" />
                                        </IonButton>
                                    </IonRow>
                                    <IonCard className="ABMClientsCardTable">
                                        <IonGrid className="ABMClientsGrid">
                                            <IonRow>
                                                <IonCol className="ABMClientsCol ABMClientsColTitle" sizeLg='1.33'>
                                                    <IonText>NOMBRE</IonText>
                                                </IonCol>
                                                <IonCol className="ABMClientsCol ABMClientsColTitle mobile-hidden" sizeLg='1.33'>
                                                    <IonText>ID</IonText>
                                                </IonCol>
                                                <IonCol className="ABMClientsCol ABMClientsColTitle mobile-hidden" sizeLg='1.33'>
                                                    <IonText>Categoría</IonText>
                                                </IonCol>
                                                <IonCol className="ABMClientsCol ABMClientsColTitle mobile-hidden " sizeLg='1.33'>
                                                    <IonText>Imagen</IonText>
                                                </IonCol>
                                                <IonCol className="ABMClientsCol ABMClientsColTitle mobile-hidden" sizeLg='1.33'>
                                                    <IonText> Mantenim. </IonText>
                                                </IonCol>
                                                <IonCol className="ABMClientsCol ABMClientsColTitle mobile-hidden col-hidden" sizeLg='1.33'>
                                                    <IonText>Creado Por</IonText>
                                                </IonCol>
                                                <IonCol className="ABMClientsCol ABMClientsColTitle mobile-hidden col-hidden" sizeLg='1.33'>
                                                    <IonText>Actualizado Última Vez</IonText>
                                                </IonCol>
                                                <IonCol className="ABMClientsCol ABMClientsColTitle mobile-hidden-280" sizeLg='1.33'>
                                                    <IonText>Modificar</IonText>
                                                </IonCol>
                                                <IonCol className="ABMClientsCol ABMClientsColTitle mobile-hidden-280" sizeLg='1.33'>
                                                    <IonText>Eliminar</IonText>
                                                </IonCol>
                                            </IonRow>
                                            {
                                                props.ABMClients.abmclients.map((client: any) => {
                                                    return (
                                                        <ABMClientsTable
                                                            key={client.service_name}
                                                            serviceData={client}
                                                            modifyCallback={renderModify}
                                                        />
                                                    )
                                                }) 
                                            }
                                        </IonGrid>
                                    </IonCard>
                                </IonCard>
                            </IonGrid>
                        </IonContent> : <Loading />
                }
            <Footer />
        </IonPage>
    )
}

//Redux state and actions dispatchers
interface StateTypes {
    ABMClients: ABMClientsDefaultStateI,
}

const mapStatetoProps = (state: StateTypes) => {
    return {
        ABMClients: state.ABMClients,
    }
};
interface dispatchTypes {
    getABMClients: () => void;
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getABMClients: () => dispatch(GetABMClients())
    };
}

export default connect(mapStatetoProps, mapDispatchToProps)(ABMClients);



