import React from 'react'
import { connect } from 'react-redux'
import { IonItem, IonList, IonLabel, IonRow, IonCol, IonButton } from '@ionic/react';

import { ClientMaintenanceDefaultStateI } from '../../store/mainInfo/clientMaintenanceReducer';
import Modal from '../Modal/Modal';

interface Props extends StateTypes {
  parentCallback: any;
  clientSelected?: string;
  showModal: boolean;
  closeModalCallback: any;
}

const ListClientsModal: React.FC<Props> = (props): JSX.Element => {


  const returnChildrenModal = (): JSX.Element => {
    return (
      <>
        <IonList className='list-clients-modal-list'>
          <IonItem className="list-clients-modal-category" lines="none">
            <IonLabel className="list-clients-modal-category-label">
              BANCOS
            </IonLabel>
          </IonItem>
          {props.clientMaintenance.loading === false && props.clientMaintenance.clientMaintenance &&
            props.clientMaintenance.clientMaintenance.map((client: any, i) => {
              return (
                client.service_category == "BANCOS" &&
                <IonItem key={i} button mode="md" onClick={() => props.parentCallback(client.service_name)} className={sessionStorage.getItem("clientUsers") == client.service_name ? "list-client-modal-selected list-clients-modal-item" : "list-clients-modal-item"}>
                  <IonLabel>
                    {client.service_display_name}
                  </IonLabel>
                </IonItem>
              )
            })

          }
          <IonItem className="list-clients-modal-category" lines="none">
            <IonLabel className="list-clients-modal-category-label">
              RETAIL
            </IonLabel>
          </IonItem>
          {props.clientMaintenance.loading === false && props.clientMaintenance.clientMaintenance &&
            props.clientMaintenance.clientMaintenance.map((client: any, i) => {
              return (
                client.service_category == "RETAIL" &&
                <IonItem key={i} button mode="md" onClick={() => props.parentCallback(client.service_name)} className={sessionStorage.getItem("clientUsers") == client.service_name ? "list-client-modal-selected list-clients-modal-item" : "list-clients-modal-item"}>
                  <IonLabel>
                    {client.service_display_name}
                  </IonLabel>
                </IonItem>
              )
            })
          }
          <IonItem className="list-clients-modal-category" lines="none">
            <IonLabel className="list-clients-modal-category-label">
              INTERNO
            </IonLabel>
          </IonItem>
          {props.clientMaintenance.loading === false && props.clientMaintenance.clientMaintenance &&
            props.clientMaintenance.clientMaintenance.map((client: any, i) => {
              return (
                client.service_category == "INTERNO" &&
                <IonItem key={i} button mode="md" onClick={() => props.parentCallback(client.service_name)} className={sessionStorage.getItem("clientUsers") == client.service_name ? "list-client-modal-selected list-clients-modal-item" : "list-clients-modal-item"}>
                  <IonLabel>
                    {client.service_display_name}
                  </IonLabel>
                </IonItem>
              )
            })
          }

          <IonItem className="list-clients-modal-category" lines="none">
            <IonLabel className="list-clients-modal-category-label">
              ADMIN
            </IonLabel>
          </IonItem>
          {props.clientMaintenance.loading === false && props.clientMaintenance.clientMaintenance &&
            props.clientMaintenance.clientMaintenance.map((client: any, i) => {
              return (
                client.service_category == "ADMIN" &&
                <IonItem key={i} button mode="md" onClick={() => props.parentCallback(client.service_name)} className={sessionStorage.getItem("clientUsers") == client.service_name ? "list-client-modal-selected list-clients-modal-item" : "list-clients-modal-item"}>
                  <IonLabel>
                    {client.service_display_name}
                  </IonLabel>
                </IonItem>
              )
            })
          }
        </IonList>
        {sessionStorage.getItem("clientUsers") !== '' &&
          <IonRow className="ion-text-center ion-padding-bottom">
            <IonCol size-lg="12" size-md="12">
              <IonButton className="list-clients-modal-button" fill="outline" size="small" onClick={() => props.closeModalCallback()}>Cancelar</IonButton>
            </IonCol>
          </IonRow>
        }

      </>
    )
  }

  return (

    <Modal

      modalChildren={
        {
          id: "",
          styles: "list-clients-modal",
          children: returnChildrenModal(),
          isOpen: props.showModal
        }
      }

    />
  )
}

//Redux state and actions dispatchers
interface StateTypes {
  clientMaintenance: ClientMaintenanceDefaultStateI
}

const mapStateToProps = (state: StateTypes) => {
  return {
    clientMaintenance: state.clientMaintenance
  };
};

export default connect(mapStateToProps)(ListClientsModal)
