import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  IonContent, IonPage, IonGrid, IonRow, IonCard, IonCardHeader, IonText, IonCardContent, getPlatforms
} from "@ionic/react";

import { SessionDefaultStateI } from "../../store/session/SessionReducer";
import { ClientsInMaintenanceDefaultStateI } from "../../store/clientsInMaintenance/clientInMaintenanceReducer";
import { GetClientsInMaintenance } from "../../store/clientsInMaintenance/clientInMaintenanceActions";

import Header from "../header/Header";
import Footer from "../Footer/Footer";
import CardClient from './CardClient';

import "./ClientsInMaintenance.css";
import Loading from "../Loading/Loading";

interface Props extends StateTypes, dispatchTypes { }

const ClientsInMaintenance: React.FC<Props> = (props): JSX.Element => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const desktop = getPlatforms().includes("desktop")

  useEffect(() => {

    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
      setShouldRefresh(true);
    }

    if(desktop){
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
    
  }, []);

  useEffect(() => {
    if (shouldRefresh) {
      setShouldRefresh(false);
      window.location.reload();
    }
  }, [shouldRefresh]);

  useEffect(() => {
    if (props.session.loading === false && props.session.loginData) {
      props.getClientsInMaintenance();
    }
  }, []);

  return (
    <IonPage>
      <Header headerText="Clientes en Mantenimiento" />
      {
        props.clientsInMaintenance.loading === false && props.clientsInMaintenance.clientsInMaintenance ?
          <IonContent fullscreen>
            <IonCard className="col-client-card-container ion-margin">
              <IonCardHeader className="cards-client-container-header">
                <IonText>
                  <h5 className="ion-no-margin ion-no-padding card-container-title">Servicios en Mantenimiento</h5>
                </IonText>
              </IonCardHeader>
              <IonCardContent >
                <IonGrid>
                  <IonRow className="conitaner-cards-client-maintenance">
                    {
                      props.clientsInMaintenance.clientsInMaintenance.map((client: any, i: number) => {
                        return (
                          <CardClient
                            serviceName={client.service_name}
                            serviceDisplayName={client.service_display_name}
                            serviceMaintenance={client.service_maintenance}
                            key={client.service_name} />
                        )
                      })
                    }
                  </IonRow>
                </IonGrid>
              </IonCardContent>
            </IonCard>
          </IonContent>
          : <Loading />
      }
      <Footer />
    </IonPage>
  );
};

//Redux state and actions dispatchers
interface StateTypes {
  session: SessionDefaultStateI;
  clientsInMaintenance: ClientsInMaintenanceDefaultStateI
}

const mapStateToProps = (state: StateTypes) => {
  return {
    session: state.session,
    clientsInMaintenance: state.clientsInMaintenance
  };
};

interface dispatchTypes {
  getClientsInMaintenance: () => void;
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getClientsInMaintenance: () => dispatch(GetClientsInMaintenance())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientsInMaintenance);