import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import { IonButton, IonIcon, IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonInput, IonItem, IonList, IonPage, IonRouterLink, IonRow, IonTitle, IonCardHeader, IonCardTitle, IonImg, IonCardSubtitle, IonText, IonSpinner, getPlatforms } from '@ionic/react';
import { eye, eyeOff } from 'ionicons/icons';

import { DoLogin } from '../../store/session/SessionActions';
import { SessionDefaultStateI } from '../../store/session/SessionReducer';
import { setStyleMode } from '../../utils/StyleUtils';

import prismaLogo from '../../assets/prismaLogo.png';
import prismaLogoDark from '../../assets/prismaLogoDark.png';
import Footer from '../Footer/Footer';

import './LoginOverview.css';

interface LoginData {
  user: string,
  password: string
}

const initialLoginData: LoginData = {
  user: '',
  password: ''
}

interface Props extends StateTypes, dispatchTypes { }

const LoginOveriew: React.FC<Props> = (props): JSX.Element => {
  const [loginData, setLoginData] = useState<LoginData>(initialLoginData)
  const [showPass, setShowPass] = useState<boolean>(false)
  const [login_invalid, setLoginInvalid] = useState(false)
  const [username_invalid, setUsername_invalid] = useState({ empty: false, long: false })
  const [pass_invalid, setPass_invalid] = useState({ empty: false, long: false })
  const [showSipnner, setShowSpinner] = useState(false)
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const desktop = getPlatforms().includes("desktop")

  useEffect(() => {

    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
      setShouldRefresh(true);
    }

    if(desktop){
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
    
  }, []);

  useEffect(() => {
    if (shouldRefresh) {
      setShouldRefresh(false);
      window.location.reload();
    }
  }, [shouldRefresh]);

  useEffect(() => {
    setStyleMode()
  }, [])

  if (props.session.loggedIn) {
    const bank_id_category = props.session.loginData.getIdToken().decodePayload()["custom:category"]
    if (bank_id_category == "INTERNO") {
      return <Redirect to="/page/intern" />
    } else {
      return <Redirect to="/page/kpigroup/services" />
    }
  }

  const validUsername = () => {
    if (loginData.user.length < 2) {
      if (loginData.user.length == 0) {
        setUsername_invalid({ empty: true, long: true })
      } else {
        setUsername_invalid({ empty: false, long: true })
      }
    } else {
      setUsername_invalid({ empty: false, long: false })
    }
  }

  const validPassword = () => {
    if (loginData.password.length < 8) {
      if (loginData.password.length == 0) {
        setPass_invalid({ empty: true, long: true })
      } else {
        setPass_invalid({ empty: false, long: true })
      }
    } else {
      setPass_invalid({ empty: false, long: false })
    }
  }

  const validForm = () => {
    let valid = false
    if (username_invalid.empty == false && pass_invalid.empty == false && loginData.user.length > 0 && loginData.password.length > 0) {
      valid = true
    }
    return valid
  }

  const handleKey = (e: any) => {
    if (e.key == "Enter") {
      doLogin()
    }
  }

  const doLogin = async () => {
    setShowSpinner(true)
    if (validForm()) {
      const response = await props.doLogin(loginData.user.trim(), loginData.password)
      if (response) {
        setShowSpinner(false)
      }
      if (response.message) {
        setLoginInvalid(true)
      }
    } else {
      validUsername()
      validPassword()
      setShowSpinner(false)
    }
  }

  const handlePasswordVisibility = () => {
    setShowPass(showPass ? false : true)
  }

  return (
    <IonPage >
      {
        (localStorage.getItem("loginFlag") == "1" || !localStorage.getItem("loginFlag")) &&
        <IonContent className="ion-text-center">
          <IonGrid className="ion-no-padding">
            <IonRow>
              <IonCol className="colLogin ion-padding-bottom">
                <IonTitle className="ion-padding ion-text-center login-title">
                  <div className="ion-text-wrap ion-padding-bottom"> MONITOREO DE SERVICIOS</div>
                </IonTitle>
              </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-center login-row">
              <IonCard className="cardLogin ion-text-center">
                <IonCardHeader className="">
                  <IonImg id="logoDark" className="login-image" src={prismaLogoDark} />
                  <IonImg id="logoLight" className="login-image" src={prismaLogo} />
                  <IonImg id="isoDark" className="ion-hide" />
                  <IonImg id="isoLight" className="ion-hide" />
                  <IonCardTitle className="login-card-title">BIENVENIDOS</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <IonList className="">
                    <IonRow className="ion-justify-content-center">
                      <IonCol size-xl="8" size-lg="8" size-md="8" size-sm="12" size-xs="12">
                        <IonCardSubtitle className="ion-text-start ion-margin-start">Usuario</IonCardSubtitle>
                        <IonItem className="login-item" lines="none">
                          <IonInput className="inputLogin"
                            type='text'
                            autocomplete="new-password"
                            name="username"
                            placeholder="Usuario"
                            required
                            value={loginData.user}
                            onIonChange={event => { validUsername(); setLoginData({ user: event.detail.value!, password: loginData.password }) }}
                            onKeyPress={handleKey} />
                        </IonItem>
                        <IonText>
                          <p className={username_invalid.empty ? "login-valid-text" : "ion-hide"}> El usuario está vacío</p>
                        </IonText>
                      </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                      <IonCol size-xl="8" size-lg="8" size-md="8" size-sm="12" size-xs="12">
                        <IonCardSubtitle className="ion-text-start ion-margin-start">Contraseña</IonCardSubtitle>
                        <IonItem className="login-item" lines="none">
                          <IonInput className="inputLogin"
                            type={showPass ? 'text' : 'password'}
                            autocomplete="new-password"
                            name="password"
                            placeholder="Ingrese su contraseña"
                            required
                            value={loginData.password}
                            onIonChange={event => { validPassword(); setLoginData({ user: loginData.user, password: event.detail.value! }) }}
                            onKeyPress={handleKey} />
                          <IonIcon icon={showPass ? eye : eyeOff} onClick={handlePasswordVisibility} className="loginEye" />
                        </IonItem>
                        <IonText>
                          <p className={pass_invalid.empty ? "login-valid-text" : "ion-hide"}> La contraseña está vacía</p>
                        </IonText>
                      </IonCol>
                      <IonText>
                        <h5 className={login_invalid ? "login-invalid ion-text-wrap" : "ion-hide"}> USUARIO Y/O CONTRASEÑA INCORRECTA</h5>
                      </IonText>
                    </IonRow>
                    <IonRow>
                      {showSipnner &&
                        <IonCol size="12" className="ion-padding">
                          <IonSpinner color="tertiary" name="lines" />
                        </IonCol>
                      }
                      <IonCol size="12" className="ion-padding">
                        <IonButton className="ion-margin-top login-button" fill="outline" onKeyPress={handleKey} onClick={doLogin}>Iniciar sesión</IonButton>
                      </IonCol>
                      <IonCol size="12" className="ion-padding">
                        <IonRouterLink className="ion-margin-top login-link" href="/page/forgot-pass">
                          ¿Olvidó su contraseña?
                        </IonRouterLink>
                      </IonCol >
                      <IonCol size="12" className="">
                        <IonRouterLink className="ion-margin-top login-link" href="/page/authenticate">
                          Verificar nuevo usuario
                        </IonRouterLink>
                      </IonCol >
                    </IonRow>
                  </IonList>
                </IonCardContent>
              </IonCard>
            </IonRow>
          </IonGrid>
          <Footer />
        </IonContent>
      }
    </IonPage>
  );
};

//Redux state and actions dispatchers

interface StateTypes {
  session: SessionDefaultStateI;
}

const mapStateToProps = (state: StateTypes) => {
  return {
    session: state.session,
  };
};

interface dispatchTypes {
  doLogin: (username: string, password: string) => any
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    doLogin: (username: string, password: string) => dispatch(DoLogin(username, password)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginOveriew);