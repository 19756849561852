import { IonContent, IonIcon, IonImg, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { iconsMenu } from './IconData';

import { KpiGroupsMenuDefaultStateI } from '../../store/mainInfo/kpiGroupsForMenuReducer';
import { GetKpiGroupsMenu } from '../../store/mainInfo/mainInfoActions';
import { SessionDefaultStateI } from '../../store/session/SessionReducer';
import { useLocation } from 'react-router-dom';

import prismaIsoDark from '../../assets/prismaIsoDark.png';
import prismaIso from '../../assets/prismaIso.png';
import prismaLogo from '../../assets/prismaLogo.png';
import prismaLogoDark from '../../assets/prismaLogoDark.png';

import './Menu.css';

interface Props extends StateTypes, dispatchTypes { }
const Menu: React.FC<Props> = (props): JSX.Element => {
  const location = useLocation();
  const [service, setService] = useState("");
  const [menuData, setMenuData] = useState<any>([]);

  useEffect(() => {
    setService(props.session.loginData ? props.session.loginData.getIdToken().decodePayload()["custom:bank_id"] : "GLCA")
  }, [])

  useEffect(() => {
    if (props.session.loading === false && props.session.loginData) {
      props.getMenuInfo()
    }
  }, [])

  useEffect(() => {
    if (props.kpiGroupsMenu.loading === false && props.kpiGroupsMenu.kpiGroupsMenu) {
      props.kpiGroupsMenu.kpiGroupsMenu.map((info: any) => {
        const iconsFilter = iconsMenu.icons.filter((icon: any) => icon.title == info.menuinfo_name)
        info.iosIcon = iconsFilter[0].iosIcon
        info.mdIcon = iconsFilter[0].mdIcon
      })
      setMenuData(props.kpiGroupsMenu.kpiGroupsMenu)
    }
  }, [props.kpiGroupsMenu.loading, props.kpiGroupsMenu.kpiGroupsMenu])

  const renderMenuItems = () => {
    return (
      <IonList id="inbox-list">
        {
          localStorage.getItem("styleMode") == "dark" ?
            <IonListHeader className="menu-title-small">
              <IonImg id="isoDark" className="menu-image" src={prismaIsoDark}/>
            </IonListHeader>
            :
            localStorage.getItem("styleMode") == "light" ?
              <IonListHeader className="menu-title-small">
                <IonImg id="isoLight" className="menu-image" src={prismaIso}/>
              </IonListHeader>
              :
              <IonListHeader className="menu-title-small"/>
        }
        {
          localStorage.getItem("styleMode") == "dark" ?
            <IonListHeader className="menu-title-large">
              <IonImg id="logoDark" className="menu-image" src={prismaLogoDark}/>
            </IonListHeader>
            : localStorage.getItem("styleMode") == "light" ?
              <IonListHeader className="menu-title-large">
                <IonImg id="logoLight" className="menu-image" src={prismaLogo}/>
              </IonListHeader>
              :
              <IonListHeader className="menu-title-large" />
        }

        <IonMenuToggle autoHide={false} className="menu-toggle">
          {
            menuData.length > 0 &&
            menuData.map((menuData: any, index: number) => {
              let path = location.pathname
              path = path.charAt(path.length - 1) === "/" ? path.substring(0, path.length - 1) : path
              return (
                <IonItem key={index} id="menu-item-1" className={path === menuData.menuinfo_url ? 'selected' : ''} routerLink={menuData.menuinfo_url} routerDirection="none" lines="none" detail={false}>
                  <IonIcon className="menu-icon" slot="start" ios={menuData.iosIcon} md={menuData.mdIcon}/>
                  <IonLabel className="ionLabelMenu">{menuData.menuinfo_title}</IonLabel>
                </IonItem>
              );
            })}
        </IonMenuToggle>
      </IonList>
    )
  }

  return (
    <IonMenu menuId="main-menu" contentId="main" className={window.screen.width < 992 ? "menu-menu" : "meu-menu split-pane-side menu-pane-visible"} type="overlay" >
      <IonContent className="menu-content">
        {renderMenuItems()}
      </IonContent>
    </IonMenu>
  )
};

//Redux state and actions dispatchers

interface StateTypes {
  session: SessionDefaultStateI,
  kpiGroupsMenu: KpiGroupsMenuDefaultStateI
}

const mapStateToProps = (state: StateTypes) => {
  return {
    session: state.session,
    kpiGroupsMenu: state.kpiGroupsMenu
  };
};

interface dispatchTypes {
  getMenuInfo: () => void
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getMenuInfo: () => dispatch(GetKpiGroupsMenu()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);