import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect, useLocation } from "react-router";
import { IonCard, IonContent, IonPage, IonGrid, IonRow, IonCol, IonCardHeader, IonCardContent, IonIcon, IonText, IonCardTitle, getPlatforms } from "@ionic/react";
import { arrowUp, arrowUpCircle, notifications, notificationsCircle, warning } from "ionicons/icons";

import { SessionDefaultStateI } from "../../store/session/SessionReducer";
import { GraphicsDefaultStateI } from "../../store/mainInfo/graphicsReducer";
import { CardsDefaultStateI } from "../../store/mainInfo/cardsReducer";
import { ClientImageDefaultStateI } from "../../store/mainInfo/clientImageReducer";
import { MessagesPrismaDefaultStateI } from "../../store/mainInfo/messagesPrismaReducer";
import { ClientMaintenanceDefaultStateI } from "../../store/mainInfo/clientMaintenanceReducer";
import { CardsType, GraphicsType } from "../../store/mainInfo/mainInfoActionsTypes";
import { GetLastMinuteAlerts } from "../../store/alerts/alertsActions";
import { GetCards, GetClientImage, GetGraphics } from "../../store/mainInfo/mainInfoActions";
import { ChangeStateDefaultStateI } from "../../store/changeState/changeStateReducer";
import { GetChangeState } from "../../store/changeState/changeStateActions";

import Header from "../header/Header";
import Footer from "../Footer/Footer";
import Cards from "../Card/Cards";
import LineChart from "../LineChart/LineChart";
import MessagesPrisma from '../MessagesPrisma/MessagesPrisma';
import SwtListClientsModal from '../SwtListClientsModal/SwtListClientsModal';
import MaintenanceComponent from '../Maintenance/MaintenanceComponent';

import "./MainOverview.css";

import Interno from "../Internos/Interno";
import Loading from "../Loading/Loading";

interface Props extends StateTypes, dispatchTypes { }

const MainOverview: React.FC<Props> = (props): JSX.Element => {
  const [graphicInfo, setGraphicInfo] = useState([]);
  const [cardsInfo, setCardsInfo] = useState([])
  const [showModal, setShowModal] = useState(false);
  const [clientSelected, setClientSelected] = useState('')
  const [categorySelected, setCategorySelected] = useState('');
  const [title, setTitle]: any = useState();
  const [scrolling, setScrolling] = useState(false)
  const [hasMessages, setHasMessages] = useState(false)
  const [notifColor, setNotifColor] = useState(false)
  const [maintenance, setMaintenance] = useState(false)
  const [inactive, setInactive] = useState(false)
  const [reRenderKey, setReRenderKey] = useState(Math.random())

  let path = useLocation().pathname;
  path = path.charAt(path.length - 1) === "/" ? path.substring(0, path.length - 1) : path;
  const pathSplit = path.split("/");
  const location = pathSplit[pathSplit.length - 1];
  const bank_id_category = props.session.loginData.getIdToken().decodePayload()["custom:category"]

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const desktop = getPlatforms().includes("desktop")

  useEffect(() => {

    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
      setShouldRefresh(true);
    }

    if(desktop){
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
    
  }, []);

  useEffect(() => {
    if (shouldRefresh) {
      setShouldRefresh(false);
      window.location.reload();
    }
  }, [shouldRefresh]);

  useEffect(() => {
    if (props.session.loading === false && props.session.loginData) {
      props.getChangeState();
      if (!sessionStorage.getItem("client")) {
        sessionStorage.setItem("client", '')
      }
      props.getGraphics();
      props.getCards();
      //if (bank_id_category == "ADMIN") {
      props.getClients()
      if (sessionStorage.getItem("client") == '') {
        setShowModal(true)
      }
      //}
    }
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem("client") !== '') {
      setShowModal(false)
    }
  }, [sessionStorage.getItem("client")])

  useEffect(() => {
    let serviceData = []
    let cardsData = []
    if (bank_id_category !== 'ADMIN') {
      if (props.graphics.loading === false && props.graphics.graphics && props.cards.loading === false && props.cards.cards) {
        serviceData =
          location !== "services" && location !== "swt"
            ? props.graphics.graphics.filter(
              (graph: any) => graph.kpi_group_name === location
            )
            : props.graphics.graphics;
        cardsData = props.cards.cards[0].cardsInfo
      }
    } else {
      if (sessionStorage.getItem("client") && sessionStorage.getItem("category") !== "INTERNO") {
        if (props.graphics.loading === false && props.graphics.graphics && props.cards.loading === false && props.cards.cards && sessionStorage.getItem("client") !== '') {
          serviceData = props.graphics.graphics.filter((graph: GraphicsType) => graph.service_name == sessionStorage.getItem("client"))
          let cards = props.cards.cards.filter((card: CardsType) => card.bank_id == sessionStorage.getItem("client"))
          cardsData = cards[0].cardsInfo
        }
      }
    }

    setGraphicInfo(serviceData);
    setCardsInfo(cardsData);
    setScrolling(false)
    setHasMessages(false)
  }, [location, sessionStorage.getItem("client"), props.graphics.loading, props.cards.loading, props.graphics.graphics, props.cards.cards]);

  useEffect(() => {
    if (bank_id_category !== 'ADMIN') {
      if (props.changeState.loading == false && props.changeState.changeState) {
        if (props.changeState.changeState[0].service_state == "inactive") {
          setInactive(true)
        } else {
          if (props.clientMaintenance.loading == false && props.clientMaintenance.clientMaintenance &&
            props.clientMaintenance.clientMaintenance[0].service_maintenance) {
            setMaintenance(true)
          }
        }
      }
    } else {
      if (props.clientMaintenance.loading == false && props.clientMaintenance.clientMaintenance && sessionStorage.getItem("client") && sessionStorage.getItem("client") != '') {
        const maintenance = props.clientMaintenance.clientMaintenance.filter(client => client.service_name == sessionStorage.getItem("client"))[0].service_maintenance
        if (maintenance) {
          setMaintenance(true)
        } else {
          setMaintenance(false)
        }
      }
    }


  }, [props.changeState.loading, props.changeState.changeState, props.clientMaintenance.loading, props.clientMaintenance.clientMaintenance, sessionStorage.getItem("client")])


  useEffect(() => {
    setReRenderKey(Math.random())
  }, [sessionStorage.getItem("client")])

  if (inactive && bank_id_category !== 'ADMIN') {
    return <Redirect to="/page/inactive" />
  }

  if (maintenance && bank_id_category !== 'ADMIN') {
    return <Redirect to="/page/maintenance" />
  }

  const showModalFunc = () => {
    setShowModal(true)
  }

  const closeModalFunc = () => {
    setShowModal(false)
  }

  const selectClient = (clientName: string, categoryName: string) => {
    sessionStorage.setItem("client", clientName)
    
    sessionStorage.setItem("clientUsers", clientName)
    setClientSelected(clientName)

    setClientSelected(clientName)
    sessionStorage.setItem("category", categoryName)
    setCategorySelected(categoryName)
    if (categoryName == "INTERNO") {
      setShowModal(false)
    }
  }

  const showIcon = (e: any) => {
    const iconElement: any = document.getElementById("icon-scrolling")
    const iconMsgElement: any = document.getElementById("icon-msg-scrolling")
    const iconElementCircle: any = document.getElementById("icon-scrolling-circle")
    const iconMsgElementCircle: any = document.getElementById("icon-msg-scrolling-circle")

    if (e.target.scrollTop < 100) {
      iconElement.style.display = "none"
      iconElementCircle.style.display = "none"
    } else {
      iconElement.style.display = "block"
      iconElementCircle.style.display = "block"
    }
    if (hasMessages) {
      if (e.target.scrollTop < 100) {
        iconMsgElement.style.display = "none"
        iconMsgElementCircle.style.display = "none"
      } else {
        iconMsgElement.style.display = "block"
        iconMsgElementCircle.style.display = "block"
      }
    } else {
      iconMsgElement.style.display = "none"
      iconMsgElementCircle.style.display = "none"
    }
  }

  const handleClickUp = () => {
    const contentElement = document.getElementById("ion-content-main")
    contentElement?.scrollIntoView({ behavior: "smooth" })
  }

  const doHasMessages = (bool: boolean) => {
    setHasMessages(bool)
  }

  const handleOpenMessage = () => {
    setScrolling(false)
  }

  const notifIconColor = (bool: boolean) => {
    setNotifColor(bool)
  }

  const renderChart = (): any => {

    return (
      <IonCard className="main-graph-card">
        <IonRow className="col-graphics-generalData">
          {graphicInfo.length > 0 && cardsInfo.length > 0 &&
            graphicInfo.map((graph: GraphicsType) => {
              if (graph.error == true) {
                return (
                  <IonCol key={graph.kpi_id} sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" className="">
                    <IonCard className="graph-card icon-card" id={graph.kpi_title_key}>
                      <IonCardHeader className="graph-card-header">
                        <IonCardTitle className="ion-text-center graph-card-title">{graph.kpi_title}</IonCardTitle>
                      </IonCardHeader>
                      <IonCardContent className="content-card-error">
                        <IonRow>
                          <IonCol style={{ textAlign: "center" }}>
                            <IonCardTitle className="ion-text-center graph-card-title">Métrica en Mantenimiento</IonCardTitle>
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol className="ion-text-center ">
                            <IonIcon className="warning-icon" icon={warning} />
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol style={{ textAlign: "center" }}>
                            <IonCardTitle className="ion-text-center graph-card-title">Por favor intente más tarde</IonCardTitle>
                          </IonCol>
                        </IonRow>
                      </IonCardContent>
                    </IonCard>

                  </IonCol>
                );
              }
              //Get card current status
              let status = false;
              cardsInfo.forEach((card: any) => {
                if (card.key === graph.kpi_title && card.alert !== "0") {
                  status = true;
                }
              });
              return (
                <IonCol className="lineChart-content" key={graph.kpi_id} sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6">
                  <LineChart
                    chartData={graph}
                    id={graph.kpi_title_key}
                    alerted={status} />
                </IonCol>
              );
            })}
        </IonRow>
      </IonCard>
    );

  };
  return (
    <IonPage>
      {
        bank_id_category !== "INTERNO" &&
        <Header timer headerText={title} parentCallback={(bank_id_category == "ADMIN") && showModalFunc} />
      }
        {
          (bank_id_category == "ADMIN") && window.screen.width < 768 && sessionStorage.getItem("client") !== "" && sessionStorage.getItem("category") !== "INTERNO" &&
          <IonRow>
            <IonCol className="ion-no-padding" onClick={() => showModalFunc()}>
              <IonCard className="ion-no-margin">
                <IonText><h5 className="ion-text-center ion-no-margin ion-padding">Seleccionar cliente</h5></IonText>
              </IonCard>
            </IonCol>
          </IonRow>
        }
      {
        sessionStorage.getItem("client") && sessionStorage.getItem("client") !== "" && sessionStorage.getItem("category") == "INTERNO" && (!maintenance) ?
        <Interno key={reRenderKey} /> : graphicInfo.length > 0 && cardsInfo.length > 0 ?
            <IonContent fullscreen onScroll={(e) => { setScrolling(true); showIcon(e) }}>
              <IonGrid id="ion-content-main">

                {
                  maintenance && (bank_id_category == "ADMIN") ?
                    <MaintenanceComponent />
                    :
                    <IonCol className="col-content-message">

                      <MessagesPrisma scrolling={scrolling} hasMessages={doHasMessages} handleOpenMsg={handleOpenMessage} notifColor={notifIconColor} />

                      <>
                        <IonRow>
                          <Cards clientSelected={sessionStorage.getItem("client")} />
                        </IonRow>
                        <IonRow>
                          {
                            renderChart()
                          }
                        </IonRow>
                      </>


                    </IonCol>
                }
              </IonGrid>
              <IonIcon icon={notificationsCircle} className={scrolling ? "icon-msgs-up-circle" : "ion-hide"} id="icon-msg-scrolling-circle" onClick={() => { handleClickUp() }} />
              <IonIcon icon={notifications} className={scrolling ? (notifColor ? "icon-msgs-up color-true" : "icon-msgs-up color-false") : "ion-hide"} id="icon-msg-scrolling" onClick={() => { handleClickUp() }} />
              <IonIcon icon={arrowUpCircle} className={scrolling ? "icon-scroll-up-circle" : "ion-hide"} id="icon-scrolling-circle" onClick={() => { handleClickUp() }} />
              <IonIcon icon={arrowUp} className={scrolling ? "icon-scroll-up" : "ion-hide"} id="icon-scrolling" onClick={() => { handleClickUp() }} />
            </IonContent>
            : <Loading />
          }
          {
            bank_id_category == "ADMIN" &&
            <SwtListClientsModal parentCallback={selectClient} showModal={showModal} closeModalCallback={closeModalFunc} />
          }
      <Footer />
    </IonPage>
  );
};

//Redux state and actions dispatchers

interface StateTypes {
  session: SessionDefaultStateI;
  cards: CardsDefaultStateI;
  graphics: GraphicsDefaultStateI;
  messagesPrisma: MessagesPrismaDefaultStateI;
  clientMaintenance: ClientMaintenanceDefaultStateI;
  clientImage: ClientImageDefaultStateI;
  changeState: ChangeStateDefaultStateI;
}

const mapStateToProps = (state: StateTypes) => {
  return {
    session: state.session,
    cards: state.cards,
    graphics: state.graphics,
    messagesPrisma: state.messagesPrisma,
    clientMaintenance: state.clientMaintenance,
    clientImage: state.clientImage,
    changeState: state.changeState
  };
};

interface dispatchTypes {
  getGraphics: () => void;
  getLastMinuteAlerts: () => void;
  getClients: () => void;
  getCards: () => void;
  getChangeState: () => void;
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getGraphics: () => dispatch(GetGraphics()),
    getLastMinuteAlerts: () => dispatch(GetLastMinuteAlerts()),
    getClients: () => dispatch(GetClientImage()),
    getCards: () => dispatch(GetCards()),
    getChangeState: () => dispatch(GetChangeState())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainOverview);