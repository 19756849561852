import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonGrid, IonIcon, IonPage, IonRow, IonText, getPlatforms } from "@ionic/react";
import { chevronDownOutline, chevronUpOutline, peopleCircle } from "ionicons/icons";

import { UserStatisticsCardDefaultStateI } from "../../store/userStatistics/userStatisticsCard/userStatisticsCardsReducer";
import { SessionDefaultStateI } from "../../store/session/SessionReducer";
import { UserSpecifyCardDefaultStateI } from "../../store/userStatistics/userSpecifyCard/userSpecifyCardReducer";
import { UserStatisticsClientDefaultStateI } from "../../store/userStatistics/userStatisticsClient/userStatisticsClientReducer";
import { UserDeleteClientDefaultStateI } from "../../store/userStatistics/userDeleteClient/userDeleteClientReducer";
import { GetUserStatisticsCard } from "../../store/userStatistics/userStatisticsCard/userStatisticsCardsActions";
import { GetUserSpecifyCard } from "../../store/userStatistics/userSpecifyCard/userSpecifyCardActions";
import { GetUserStatisticsClient } from "../../store/userStatistics/userStatisticsClient/userStatisticsClientActions";
import { GetUserDeleteClient } from "../../store/userStatistics/userDeleteClient/userDeleteClientActions";

import Header from "../header/Header";
import UserGraphicsGeneral from "./userGraphicsGeneral";
import UserSpecifyCard from "./userSpecifyCard";
import UsersSpecifyGraphics from "./userSpecifyGraphics";
import UserClientTable from "./userClientTable";
import ListClientsModal from "../ABMUsers/ListClientsModal";
import UserDeleteClient from "./userDeleteClient";
import Footer from "../Footer/Footer";

import './userStatistics.css';
import Loading from "../Loading/Loading";

interface Props extends StateTypes, dispatchTypes { }

const UsersStatisticsMainView: React.FC<Props> = (props): JSX.Element => {
    const [userStatisticsGeneralData, setUserStatisticsData] = useState<any>([]);
    const [userSpecifyData, setUserSpecifyData] = useState<any>([]);
    const [userDeleteData, setUserDeleteData] = useState<any>([]);
    const [userSpecifyGraphicBank, setUserSpecifyGraphicBank] = useState<any>([])
    const [userSpecifyGraphicRetail, setUserSpecifyGraphicRetail] = useState<any>([])
    const [userSpecifyGraphicInterno, setUserSpecifyGraphicInterno] = useState<any>([])
    const [showModal, setShowModal] = useState(false);
    const [clientSelected, setClientSelected] = useState('');
    const [userStatisticsClientData, setUserStatisticsClientData] = useState<any>([]);
    const [specifyGraphicsBancoOpen, setSpecifyGraphicsBancoOpen] = useState(true);
    const [specifyGraphicsRetailOpen, setSpecifyGraphicsRetailOpen] = useState(true);
    const [specifyGraphicsInternoOpen, setSpecifyGraphicsInternoOpen] = useState(true);
    const [userTableOpen, setUserTableOpen] = useState(true);
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });
    const [shouldRefresh, setShouldRefresh] = useState(false);
    const desktop = getPlatforms().includes("desktop")

    useEffect(() => {
  
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight
        });
        setShouldRefresh(true);
      }
  
      if(desktop){
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }
      
    }, []);

    useEffect(() => {
        if (shouldRefresh) {
            setShouldRefresh(false);
            window.location.reload();
        }
    }, [shouldRefresh]);

    useEffect(() => {
        if (props.session.loading === false && props.session.loginData) {
            props.getUserStatisticsClient();
            props.getUserStatisticsCard();
            props.getUserSpecifyCard();
            props.getUserDeleteClient();
            if (!sessionStorage.getItem("clientUsers")) {
                sessionStorage.setItem("clientUsers", '')
            }
            if (sessionStorage.getItem("clientUsers") == '') {
                sessionStorage.setItem("clientUsers", 'BBVA')
            }
        }
    }, [])

    useEffect(() => {
        if (sessionStorage.getItem('clientUsers') !== '') {
            setShowModal(false);
        }
    }, [sessionStorage.getItem("clientUsers")]);

    useEffect(() => {
        let userStatisticsData: any = [];
        if (props.userStatisticsCard.loading === false && props.userStatisticsCard.userStatisticsCard) {
            userStatisticsData = props.userStatisticsCard.userStatisticsCard
            setUserStatisticsData(userStatisticsData);
        }
    }, [props.userStatisticsCard.loading, props.userStatisticsCard.userStatisticsCard])

    useEffect(() => {
        if (props.userSpecifyCard.loading === false && props.userSpecifyCard.userSpecifyCard) {
            setUserSpecifyData(props.userSpecifyCard.userSpecifyCard)
        }
    }, [props.userSpecifyCard.loading, props.userSpecifyCard.userSpecifyCard])

    useEffect(() => {
        if (props.userSpecifyCard.loading === false && props.userSpecifyCard.userSpecifyCard) {
            const bank: any = userSpecifyData.filter((userSpecify: any) => userSpecify.category.includes("BANCOS"))
            setUserSpecifyGraphicBank(bank)
            const retail: any = userSpecifyData.filter((userSpecify: any) => userSpecify.category.includes("RETAIL"))
            setUserSpecifyGraphicRetail(retail)
            const interno: any = userSpecifyData.filter((userSpecify: any) => userSpecify.category.includes("INTERNO") || userSpecify.category.includes("ADMIN"))
            setUserSpecifyGraphicInterno(interno)
        }
    }, [props.userSpecifyCard.loading, props.userSpecifyCard.userSpecifyCard, userSpecifyData])

    useEffect(() => {
        if (props.userStatisticsClient.loading === false && props.userStatisticsClient.userStatisticsClient) {
            const userStatisticsClient: any = props.userStatisticsClient.userStatisticsClient.filter((userClient: { bank_id: string; }) => userClient.bank_id.toUpperCase() === sessionStorage.getItem('clientUsers'))
            setUserStatisticsClientData(userStatisticsClient)
        }
    }, [sessionStorage.getItem('clientUsers'), props.userStatisticsClient.loading, props.userStatisticsClient.userStatisticsClient]);

    useEffect(() => {
        if (props.userDeleteClient.loading === false && props.userDeleteClient.userDeleteClient) {
            setUserDeleteData(props.userDeleteClient.userDeleteClient)
        }
    }, [props.userDeleteClient.loading, props.userDeleteClient.userDeleteClient])

    const selectClient = (clientName: string) => {
        sessionStorage.setItem("clientUsers", clientName)
        setClientSelected(clientName)
    }

    const showModalFunc = () => {
        setShowModal(true);
    }

    const closeModalFunc = () => {
        setShowModal(false);
    }

    const returnUserTable = () => {
        return (
            <IonCard>
                <IonCardHeader className="user-card-header" onClick={() => setUserTableOpen(!userTableOpen)}>Usuarios por Cliente
                    <IonIcon icon={userTableOpen ? chevronUpOutline : chevronDownOutline} className="display-user-icon" />
                </IonCardHeader>
                <IonGrid className={userTableOpen ? `user-no-hidden` : `user-hidden`}>
                    <IonRow className="ion-align-items-center">
                        <IonCol sizeXl="0.3" sizeLg="0.5" sizeMd="0.5" sizeSm="2" sizeXs="2" className="user-iconClient-col">
                            <IonButton title="Seleccionar cliente" fill="clear" type="button" className="user-table-button" onClick={() => showModalFunc()}>
                                <IonIcon icon={peopleCircle} className="user-table-select-client" />
                            </IonButton>
                        </IonCol>
                        <IonCol sizeXl="11.5" sizeLg="11.5" sizeMd="11.5" sizeSm="10" sizeXs="10">
                            <IonText className="user-table-client">
                                <h5> {sessionStorage.getItem("clientUsers")} </h5>
                            </IonText>
                        </IonCol>
                    </IonRow>
                    <IonCardContent className='user-table-tables user-table-card user-table-margin-card'>
                        <IonRow className='user-table-rows'>
                            <IonCol className='user-table-title-tables' sizeXl="2" sizeLg="2" sizeMd="6" sizeXs="6">Username</IonCol>
                            <IonCol className='mobile-hidden user-table-title-tables' sizeXl="1.5" sizeLg="1.5" sizeMd="1.5">Nombre</IonCol>
                            <IonCol className='mobile-hidden user-table-title-tables' sizeXl="1.5" sizeLg="1.5" sizeMd="1.5">Apellido</IonCol>
                            <IonCol className='mobile-hidden user-table-title-tables' sizeXl="4" sizeLg="4" sizeMd="4">Email</IonCol>
                            <IonCol className='mobile-hidden user-table-title-tables' sizeXl="1.50" sizeLg="1.50" sizeMd="1.50">Fecha Creación</IonCol>
                            <IonCol className='user-table-title-tables' sizeXl="1.50" sizeLg="1.50" sizeMd="6" sizeXs="6">Última vez activo</IonCol>
                        </IonRow>
                        {
                            userStatisticsClientData.length > 0 ?
                                userStatisticsClientData.map((clientData: any) => {
                                    return (
                                        <UserClientTable key={clientData.username} userClientData={clientData} />
                                    )
                                }) :
                                <IonRow className='user-table-rows'>
                                    <IonCol> No hay Usuarios Registrados</IonCol>
                                </IonRow>
                        }
                        <ListClientsModal
                            parentCallback={selectClient}
                            showModal={showModal}
                            closeModalCallback={closeModalFunc} />
                    </IonCardContent>
                </IonGrid>
            </IonCard >
        )
    }

    return (
        <IonPage>
            <Header headerText="Estadísticas Usuarios" />
            {
                (userStatisticsGeneralData.inactiveUsers && userSpecifyData.length > 0) ?
                    <IonContent>
                        <IonRow className="content-user-statistics">
                            <IonCol className="content-card-general">
                                {props.userStatisticsCard.userStatisticsCard &&
                                    <IonCard className="">
                                        <IonCardHeader className='user-card-header'>General</IonCardHeader>
                                        <UserGraphicsGeneral data={props.userStatisticsCard.userStatisticsCard} />
                                    </IonCard>
                                }
                            </IonCol>
                            <IonCol>
                            {
                            userSpecifyData.length > 0 &&
                            <UserSpecifyCard userData={userSpecifyData} />
                        }
                            </IonCol>
                        </IonRow>

                        
                        <IonCard>
                            <IonCardHeader className='user-card-header' onClick={() => setSpecifyGraphicsBancoOpen(!specifyGraphicsBancoOpen)}>Cantidad de Usuarios - Banco
                                <IonIcon icon={specifyGraphicsBancoOpen ? chevronUpOutline : chevronDownOutline} className="display-user-icon" />
                            </IonCardHeader>
                            <IonCardContent className={specifyGraphicsBancoOpen ? `user-content-cards` : `user-hidden`}>
                                {userSpecifyGraphicBank.length > 0 &&

                                    userSpecifyGraphicBank.map((client: any) => {
                                        return (
                                            <UsersSpecifyGraphics key={client.bank_id} data={client} />
                                        )
                                    })
                                }
                            </IonCardContent>
                        </IonCard>
                        <IonCard>
                            <IonCardHeader className='user-card-header' onClick={() => setSpecifyGraphicsRetailOpen(!specifyGraphicsRetailOpen)}>Cantidad de Usuarios - Retail
                                <IonIcon icon={specifyGraphicsRetailOpen ? chevronUpOutline : chevronDownOutline} className="display-user-icon" />
                            </IonCardHeader>
                            <IonCardContent className={specifyGraphicsRetailOpen ? `user-content-cards` : `user-hidden`}>
                                {userSpecifyGraphicRetail.length > 0 &&
                                    userSpecifyGraphicRetail.map((client: any) => {
                                        return (
                                            <UsersSpecifyGraphics key={client.bank_id} data={client} />
                                        )
                                    })
                                }
                            </IonCardContent>
                        </IonCard>
                        <IonCard>
                            <IonCardHeader className='user-card-header' onClick={() => setSpecifyGraphicsInternoOpen(!specifyGraphicsInternoOpen)}>Cantidad de Usuarios - Interno
                                <IonIcon icon={specifyGraphicsInternoOpen ? chevronUpOutline : chevronDownOutline} className="display-user-icon" />
                            </IonCardHeader>
                            <IonCardContent className={specifyGraphicsInternoOpen ? `user-content-cards` : `user-hidden`}>
                                {userSpecifyGraphicInterno.length > 0 &&
                                    userSpecifyGraphicInterno.map((client: any) => {
                                        return (
                                            <UsersSpecifyGraphics key={client.bank_id} data={client} />
                                        )
                                    })
                                }
                            </IonCardContent>
                        </IonCard>
                        {
                            props.userStatisticsClient.userStatisticsClient?.length > 0 &&
                            returnUserTable()
                        }
                        <UserDeleteClient userDeleteData={userDeleteData} />
                    </IonContent>
                    : <Loading />
            }
            <Footer />
        </IonPage>
    )
}

interface StateTypes {
    session: SessionDefaultStateI;
    userStatisticsCard: UserStatisticsCardDefaultStateI;
    userSpecifyCard: UserSpecifyCardDefaultStateI;
    userStatisticsClient: UserStatisticsClientDefaultStateI;
    userDeleteClient: UserDeleteClientDefaultStateI;
}

const mapStateProps = (state: StateTypes) => {
    return {
        session: state.session,
        userStatisticsCard: state.userStatisticsCard,
        userSpecifyCard: state.userSpecifyCard,
        userStatisticsClient: state.userStatisticsClient,
        userDeleteClient: state.userDeleteClient
    }
}

interface dispatchTypes {
    getUserStatisticsCard: () => void;
    getUserSpecifyCard: () => void;
    getUserStatisticsClient: () => void;
    getUserDeleteClient: () => void;
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getUserStatisticsCard: () => dispatch(GetUserStatisticsCard()),
        getUserSpecifyCard: () => dispatch(GetUserSpecifyCard()),
        getUserStatisticsClient: () => dispatch(GetUserStatisticsClient()),
        getUserDeleteClient: () => dispatch(GetUserDeleteClient())
    }
}

export default connect(mapStateProps, mapDispatchToProps)(UsersStatisticsMainView);