import React, { useEffect, useState } from 'react';
import { getPlatforms, IonCard, IonCardContent, IonCardHeader, IonCol, IonIcon } from '@ionic/react';
import { save } from 'ionicons/icons';

import Chart from 'chart.js/auto';
import dayjs from 'dayjs';

import './Intern.css';

interface colorsBorderAndFill {
    border: string,
    fill: any
}

type dataInternType = {
    label: string,
    data: number[],
    fill: boolean,
    backgroundColor: string,
    borderColor: string
}
type chartDataInternType = {
    labels: string[],
    datasets: dataInternType[]
}
interface GraphicInternData {
    intern_id: string,
    intern_title: string,
    intern_threshold: boolean,
    keys: string[],
    values: number[],
    limit_values: number[]
}
export type InternGraf = {
    intern_id: string,
    intern_type: string,
    intern_title: string,
    intern_title_key: string,
    intern_linechart_label: string,
    intern_threshold: boolean,
    keys: [],
    values: number[],
    valuesReverse?: number[],
    limit_values: number[]
};
interface Props {
    internData: InternGraf
    internDataCoelsa?: Array<InternGraf>
    internDataTime?: Array<InternGraf>
    id?: string
    alerted: boolean
    graphId?: string
    title?: string
    valuesReverse?: any
    type?: string
}

const InternGraphics: React.FC<Props> = (props): JSX.Element => {

    const typeGraphics = ['combined', 'reversedGeneral', 'entities', 'lineReverse', 'evolution', 'tiempoJPOS', 'tiempoApache', 'reports','lifespan']


    const [showSaveIcon, setShowSaveIcon] = useState(false)
    const graphID = props.graphId ? props.graphId : `graphResolutores-${props.internData.intern_id}`
    const textColor = localStorage.getItem('styleMode') === 'dark' ? 'white' : 'black'
    const umbralColor = getComputedStyle(document.documentElement)
        .getPropertyValue('--cust-color-umbral-rgb')
    const quantityColor = getComputedStyle(document.documentElement)
        .getPropertyValue('--cust-color-quantity-rgb')
    const percentageColor = getComputedStyle(document.documentElement)
        .getPropertyValue('--cust-color-percentage-rgb')
    const alertedColor = getComputedStyle(document.documentElement)
        .getPropertyValue('--cust-color-alerted-rgb')

    useEffect(() => {
        GraphicIntern()

    }, [])
    useEffect(() => {
        const mobile = getPlatforms().includes("mobile")
        const mobileWeb = getPlatforms().includes("mobileweb")
        const desktop = getPlatforms().includes("desktop")
        if (mobile && !mobileWeb) {
            setShowSaveIcon(false)
        }
        if (mobileWeb || desktop) {
            setShowSaveIcon(true)
        }
    }, [])

    const getLabels = (internKey: GraphicInternData): string[] => {
        const formattedData = internKey.keys.map((el: any) => {
            return dayjs(el).format("HH:mm")
        })
        return formattedData;
    };

    const getGradient = (ctx: any, color: string) => {
        const gradient = ctx.createLinearGradient(0, 0, 0, 300);
        const points: number = props.internData.values.length;
        const inc: number = 1 / points;
        let acumGrad: number = 0
        let acumAlpha: number = 1

        for (let i = 0; i < points; i++) {
            gradient.addColorStop(acumGrad, `rgba(${color}, ${acumAlpha})`);
            acumGrad += inc
            acumAlpha -= inc
        }

        return gradient;
    };

    const getDatasets = (intern: InternGraf): dataInternType[] => {
        const canvas = document.getElementById(graphID) as HTMLCanvasElement;
        const ctx: any = canvas.getContext('2d');
        const colour = intern.intern_type === "QUANTITY" ?
            quantityColor :
            percentageColor
        const color = props.alerted ? alertedColor : colour

        const gradientUmbral = ctx.createLinearGradient(0, 0, 0, 300);
        gradientUmbral.addColorStop(0, `rgba(${umbralColor}, 0.3)`);
        gradientUmbral.addColorStop(0.5, `rgba(${umbralColor}, 0.3)`);
        gradientUmbral.addColorStop(1, `rgba(${umbralColor}, 0.3)`);

        const gradientUmbralApache = ctx.createLinearGradient(0, 0, 0, 300);
        gradientUmbralApache.addColorStop(0, `rgba(${umbralColor}, 0.1)`);
        gradientUmbralApache.addColorStop(0.5, `rgba(${umbralColor}, 0.1)`);
        gradientUmbralApache.addColorStop(1, `rgba(${umbralColor}, 0.1)`);

        //ReverseGeneral
        const umbralColorRed = getComputedStyle(document.documentElement)
            .getPropertyValue('--cust-color-alerted-rgb')
        const gradientUmbralRed = ctx.createLinearGradient(0, 0, 0, 300);
        gradientUmbralRed.addColorStop(0, `rgba(${umbralColorRed}, 0.3)`);
        gradientUmbralRed.addColorStop(0.5, `rgba(${umbralColorRed}, 0.3)`);
        gradientUmbralRed.addColorStop(1, `rgba(${umbralColorRed}, 0.3)`);


        //LineReversos
        const lineReversos = getComputedStyle(document.documentElement)
            .getPropertyValue('--cust-color-umbral-rgb')
        const gradientLineReversosGreen = ctx.createLinearGradient(0, 0, 0, 300);
        gradientLineReversosGreen.addColorStop(0, `rgba(${lineReversos}, 0.3)`);
        gradientLineReversosGreen.addColorStop(0.5, `rgba(${lineReversos}, 0.3)`);
        gradientLineReversosGreen.addColorStop(1, `rgba(${lineReversos}, 0.3)`);
        const gradientValues = getGradient(ctx, color);

        let datasets = intern.intern_threshold ? [
            {
                label: "Umbral",
                data: intern.limit_values,
                fill: true,
                backgroundColor: gradientUmbral,
                borderColor: `rgb(${umbralColor})`
            },
            {
                label: intern.intern_linechart_label,
                data: intern.values,
                fill: true,
                backgroundColor: gradientValues,
                borderColor: `rgb(${color})`
            },
        ] :
            [
                {
                    label: intern.intern_linechart_label,
                    data: intern.values,
                    fill: true,
                    backgroundColor: gradientValues,
                    borderColor: `rgb(${color})`
                },
            ]

        switch (props.type) {
            case 'lineReverse':
                datasets.push({
                    label: "Reverso",
                    data: intern.valuesReverse!,
                    fill: true,
                    backgroundColor: gradientUmbralRed,
                    borderColor: 'red'
                })
                break;
            case 'reversedGeneral':
                return datasets = [
                    {
                        label: "",
                        data: intern.values,
                        fill: true,
                        backgroundColor: gradientUmbralRed,
                        borderColor: 'red'
                    }
                ]
            case 'tiempoApache':
                return datasets = [
                    {
                        label: "Umbral",
                        data: intern.limit_values,
                        fill: true,
                        backgroundColor: gradientUmbralApache,
                        borderColor: `rgb(${umbralColor})`
                    },
                    {
                        label: "TIEMPO REPUESTA APACHE-DEBIN-COELSA",
                        data: intern.values,
                        fill: true,
                        backgroundColor: gradientValues,
                        borderColor: `rgb(${color})`
                    },
                ]
            case 'combined':
                return datasets = buildDataSetsCoelsa(ctx);
            case 'tiempoJPOS':
                return datasets = buildDataSetsTiempo(ctx).reverse();
            case 'lifespan':
                return datasets = buildDataSetsLifespan(ctx).reverse();

        }
        return datasets;
    };

    const buildColorCoelsa = (title: String, ctx: any) => {

        const titleAndColors: { [title: string]: string } = {
            "Time Out LAPP-PRPM588": "--cust-color-umbral-rgb",
            "Time Out LAPP-PRPM589": "--cust-color-alerted-rgb",
            "Time Out LAPP-PRPM1105": "--custom-color-coelsa-bordo-rgb",
            "Time Out LAPP-PRPM1106": "--custom-color-coelsa-rgb"
        };

        for (const key in titleAndColors) {
            if (key === title) {
                const umbralColorCoelsa = getComputedStyle(document.documentElement)
                    .getPropertyValue(titleAndColors[key].trim());
                const gradientCoelsa = ctx.createLinearGradient(0, 0, 0, 300);
                gradientCoelsa.addColorStop(0, `rgba(${umbralColorCoelsa}, 0.3)`);
                gradientCoelsa.addColorStop(0.5, `rgba(${umbralColorCoelsa}, 0.3)`);
                gradientCoelsa.addColorStop(1, `rgba(${umbralColorCoelsa}, 0.3)`);

                return (
                    {
                        border: `rgb(${umbralColorCoelsa})`,
                        fill: gradientCoelsa
                    }
                );

            }
        }
    }

    const buildDataSetsCoelsa = (ctx: any) => {
        const datasets: dataInternType[] = [];
        let colorsBorderAndFill: colorsBorderAndFill = {
            border: '',
            fill: ''
        };
        props.internDataCoelsa!.map((graphic: any) => {
            colorsBorderAndFill = buildColorCoelsa(graphic.intern_linechart_label, ctx)!;
            datasets.push({
                label: graphic.intern_title,
                data: graphic.values,
                fill: true,
                backgroundColor: colorsBorderAndFill.fill,
                borderColor: colorsBorderAndFill.border
            })
        })
        return datasets
    }


    const buildColorTiempoJPOS = (title: String, ctx: any) => {

        const titleAndColors: { [title: string]: string } = {
            "AVG-TIME-150001": "--cust-color-quantity-rgb",
            "AVG-TIME-150100": "--cust-color-percentage-rgb",
            "AVG-TIME-151100": "--custom-color-avg-time-1-rgb",
            "Umbral": "--cust-color-umbral-rgb"
        };

        for (const key in titleAndColors) {
            if (key === title) {
                const umbralColorTiempo = getComputedStyle(document.documentElement)
                    .getPropertyValue(titleAndColors[key].trim());
                const gradientTiempo = ctx.createLinearGradient(0, 0, 0, 300);
                gradientTiempo.addColorStop(0, `rgba(${umbralColorTiempo}, 0.1)`);
                gradientTiempo.addColorStop(0.5, `rgba(${umbralColorTiempo}, 0.1)`);
                gradientTiempo.addColorStop(1, `rgba(${umbralColorTiempo}, 0.1)`);

                return (
                    {
                        border: `rgb(${umbralColorTiempo})`,
                        fill: gradientTiempo
                    }
                );

            }
        }
    }

    const buildDataSetsTiempo = (ctx: any) => {
        const datasets: dataInternType[] = [];
        let colorsBorderAndFillTiempo: colorsBorderAndFill = {
            border: '',
            fill: ''
        };
        props.internDataTime!.map((graphic: any) => {
            colorsBorderAndFillTiempo = buildColorTiempoJPOS(graphic.intern_title, ctx)!;
            if (graphic.intern_title == "AVG-TIME-150001") {
                datasets.push(
                    {
                        label: "Umbral",
                        data: graphic.limit_values,
                        fill: true,
                        backgroundColor: colorsBorderAndFillTiempo.fill,
                        borderColor: `rgb(${umbralColor})`
                    },
                    {
                        label: graphic.intern_title,
                        data: graphic.values,
                        fill: true,
                        backgroundColor: colorsBorderAndFillTiempo.fill,
                        borderColor: colorsBorderAndFillTiempo.border
                    }
                )
            } else {
                datasets.push(
                    {
                        label: graphic.intern_title,
                        data: graphic.values,
                        fill: true,
                        backgroundColor: colorsBorderAndFillTiempo.fill,
                        borderColor: colorsBorderAndFillTiempo.border
                    }
                )
            }
        })
        return datasets
    }

    const buildColorLifespan = (title: String, ctx: any) => {

        const titleAndColors: { [title: string]: string } = {
            "LIFESPAN TOTAL 588": "--cust-color-quantity-rgb",
            "LIFESPAN TOTAL 589": "--cust-color-percentage-rgb",
            "Umbral": "--cust-color-umbral-rgb"
        };

        for (const key in titleAndColors) {
            if (key === title) {
                const umbralColorTiempo = getComputedStyle(document.documentElement)
                    .getPropertyValue(titleAndColors[key].trim());
                const gradientTiempo = ctx.createLinearGradient(0, 0, 0, 300);
                gradientTiempo.addColorStop(0, `rgba(${umbralColorTiempo}, 0.1)`);
                gradientTiempo.addColorStop(0.5, `rgba(${umbralColorTiempo}, 0.1)`);
                gradientTiempo.addColorStop(1, `rgba(${umbralColorTiempo}, 0.1)`);

                return (
                    {
                        border: `rgb(${umbralColorTiempo})`,
                        fill: gradientTiempo
                    }
                );

            }
        }
    }

    const buildDataSetsLifespan = (ctx: any) => {
        const datasets: dataInternType[] = [];
        let colorsBorderAndFillTiempo: colorsBorderAndFill = {
            border: '',
            fill: ''
        };
        props.internDataTime!.map((graphic: any) => {
            colorsBorderAndFillTiempo = buildColorLifespan(graphic.intern_title, ctx)!;
            if (graphic.intern_title == "LIFESPAN TOTAL 588") {
                datasets.push(
                    {
                        label: "Umbral",
                        data: graphic.limit_values,
                        fill: true,
                        backgroundColor: colorsBorderAndFillTiempo.fill,
                        borderColor: `rgb(${umbralColor})`
                    },
                    {
                        label: graphic.intern_title,
                        data: graphic.values,
                        fill: true,
                        backgroundColor: colorsBorderAndFillTiempo.fill,
                        borderColor: colorsBorderAndFillTiempo.border
                    }
                )
            } else {
                datasets.push(
                    {
                        label: graphic.intern_title,
                        data: graphic.values,
                        fill: true,
                        backgroundColor: colorsBorderAndFillTiempo.fill,
                        borderColor: colorsBorderAndFillTiempo.border
                    }
                )
            }
        })
        return datasets
    }

    const saveChart = () => {
        const url_base64 = document.getElementById(graphID) as HTMLCanvasElement;
        const link = document.getElementById(`download-${props.internData.intern_title}`) as HTMLLinkElement;
        link.href = url_base64.toDataURL('image/png');
    };

    const GraphicIntern = () => {
        const data: chartDataInternType = {
            labels: getLabels(props.internData),
            datasets: getDatasets(props.internData),
        };
        const options: any = {
            responsive: true,
            maintainAspectRatio: typeGraphics.includes(props.type!) ? false : true,
            elements: {
                line: {
                    // A higher value makes the line look skewed at this ratio.
                    tension: 0.4,
                    borderWidth: 1
                },
                point: {
                    radius: 0
                }
            },
            scales: {
                x: {
                    grid: {
                        color: "rgba(127, 131, 137,0.2)",
                        borderDash: [4, 2],
                    },
                    ticks: {
                        color: "#929ba8",
                        maxTicksLimit: props.type == "reversedGeneral" ? 50 : 8,
                        font: {
                            size: 10,
                        }
                    },
                },
                y:
                {
                    grid: {
                        color: "rgba(127, 131, 137,0.2)",
                        borderDash: [4, 2],
                    },
                    beginAtZero: true,
                    ticks: {
                        color: "#929ba8",
                        maxTicksLimit: 8,
                        font: {
                            size: 10,
                        }
                    },
                }
            },
            interaction: {
                mode: 'nearest',
                intersect: false
            },
            plugins: {
                zoom: {
                    pan: {
                        enabled: true,
                        mode: 'xy',
                        threshold: 10,
                    },
                    zoom: {
                        wheel: {
                            enabled: true
                        }
                    }
                },
                legend: {
                    display: props.type == "reversedGeneral" ? false : true,
                    align: "center",
                    reverse: true,
                    labels: {
                        color: textColor,
                        usePointStyle: true,
                        pointStyle: 'rectRounded',
                        padding: 15,
                        font: {
                            size: 8,

                        }
                    },
                }
            },
            layout: {
                padding: {
                    right: 15
                }
            }
        };

        const content: any = document.getElementById(`canvas-content-${graphID}`)
        content.style.minwidth = '100%'
        content.style.minheight = '100%'


        const canvas = document.getElementById(graphID) as HTMLCanvasElement;
        const ctx: any = canvas.getContext('2d');



        const myChart = new Chart(ctx, {
            type: 'line',
            data: data,
            options: options
        })
        //const content: any = document.getElementById(`canvas-content-${graphID}`)
        if (content) {
            content.style.display = "none"
        }
    };

    setTimeout(function () {
        const content: any = document.getElementById(`canvas-content-${graphID}`)
        if (content) {
            content.style.display = "block"
        }
    }.bind(this), 2000);

    const cssGraphics = () => {
        if (typeGraphics.includes(props.type!)) {
            if (props.type! == "lineReverse") {
                return (
                    'graph-content-lineReverse'
                )
            } else if (props.type! == 'evolution') {
                return (
                    'graph-content-evolution'
                )
            } else if (props.type! == 'tiempoJPOS') {
                return (
                    'graph-content-jpos'
                )
            } else if (props.type! == 'lifespan') {
                return (
                    'graph-content-lifespan'
                )
            } else if (props.type! == 'reports') {
                return (
                    'graph-intern-content'
                )
            } else {
                return (
                    'graph-content-combinedCoelsaReverse'
                )
            }
        } else {
            return (
                'graph-intern-content'
            )
        }
    }

    const cssGraphicsTitle = () => {
        if (typeGraphics.includes(props.type!)) {
            if (props.type! == "reports") {
                return (
                    'graph-intern-reports'
                )
            } else {
                return (
                    'graph-intern'
                )
            }
        } else {
            return (
                'graph-intern'
            )
        }
    }

    return (
        <IonCard className={cssGraphicsTitle()} id={props.internData.intern_id}>
            <IonCardHeader className="graph-intern-header">
                <IonCol className=' ion-text-center graph-intern-title'>
                    {props.title}
                </IonCol>
                {showSaveIcon &&
                    <a className="download-button"
                        id={`download-${props.internData.intern_title}`}
                        download={`${props.internData.intern_title}`}
                        onClick={saveChart}>
                        <IonIcon icon={save} className="graph-intern-icon" id="iconSave" />
                    </a>}
            </IonCardHeader>
            <IonCardContent id={`canvas-content-${graphID}`} className={cssGraphics()}>
                <canvas className='graphic' id={graphID}></canvas>
            </IonCardContent>
        </IonCard>
    )
}

export default InternGraphics;