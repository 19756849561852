import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { IonCard, IonCol, IonContent, IonGrid, IonIcon, IonPage, IonRow, IonText, getPlatforms } from "@ionic/react";
import { notificationsCircle, notifications, arrowUpCircle, arrowUp } from "ionicons/icons";

import { SessionDefaultStateI } from "../../store/session/SessionReducer";
import { ClientMaintenanceDefaultStateI } from "../../store/mainInfo/clientMaintenanceReducer";
import { GetClientImage } from "../../store/mainInfo/mainInfoActions";
import { InternosCardsDefaultStateI } from "../../store/Internos/InternosCards/internosCardsReducer";
import { InternosGraphicsDefaultStateI } from "../../store/Internos/InternosGraphics/internosGraphicsReducer";
import { ChangeStateDefaultStateI } from "../../store/changeState/changeStateReducer";
import { GetChangeState } from "../../store/changeState/changeStateActions";

import Header from "../header/Header";
import Footer from "../Footer/Footer";
import Loading from "../Loading/Loading";
import DebinCard from "./DebinCard";
import DebinButton from "./DebinButton";
import SwtListClientsModal from "../SwtListClientsModal/SwtListClientsModal";
import MessagesPrisma from "../MessagesPrisma/MessagesPrisma";
import MaintenanceComponent from "../Maintenance/MaintenanceComponent";
import DebinCardGraphics from "./DebinCardGraphics";

import './Debin.css';

interface Props extends StateTypes, dispatchTypes { }

const DebinMainView: React.FC<Props> = (props): JSX.Element => {

    const [cardInfo, setCardInfo] = useState<any>([]);
    const [cardReversalInfo, setCardReversalInfo] = useState<any>([]);
    const [graphicsInfo, setGraphicsInfo] = useState<any>([]);
    const [showModal, setShowModal] = useState(false);
    const [graphicReversoGeneral, setGraphicReversoGeneral] = useState<any>([]);
    const [clientSelected, setClientSelected] = useState('');
    const [categorySelected, setCategorySelected] = useState('');
    const [hasMessages, setHasMessages] = useState(false);
    const [notifColor, setNotifColor] = useState(false);
    const [scrolling, setScrolling] = useState(false);
    const [coelsaGraphics, setCoelsaGraphics] = useState<any>([]);
    const [entitiesGraphic, setEntitiGraphic] = useState<any>([]);
    const [tiemposGraphic, setTiemposGraphic] = useState<any>([]);
    const [apacheGraphic, setApacheGraphic] = useState<any>([]);
    const [lifespanGraphic, setLifespanGraphic] = useState<any>([]);
    const [maintenance, setMaintenance] = useState(false);
    const [inactive, setInactive] = useState(false);
    const [cardMP, setCardMP] = useState<any>([]);
    const [cardAVGTime, setCardAVGTime] = useState<any>([]);
    const [cardMauApache, setCardMauApache] = useState<any>([]);
    const [cardLifespan, setCardLifespan] = useState<any>([]);

    const bank_id_category = props.session.loginData.getIdToken().decodePayload()["custom:category"]
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
      });
      const [shouldRefresh, setShouldRefresh] = useState(false);
      const desktop = getPlatforms().includes("desktop")

      useEffect(() => {
    
        function handleResize() {
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight
          });
          setShouldRefresh(true);
        }
    
        if(desktop){
          window.addEventListener('resize', handleResize);
          return () => window.removeEventListener('resize', handleResize);
        }
        
      }, []);
    
      useEffect(() => {
        if (shouldRefresh) {
          setShouldRefresh(false);
          window.location.reload();
        }
      }, [shouldRefresh]);

    useEffect(() => {
        if (props.session.loading === false && props.session.loginData) {
            props.getChangeState();
            if (!sessionStorage.getItem("client")) {
                sessionStorage.setItem("client", '')
            }
            if (bank_id_category == "ADMIN") {
                props.getClients()
                if (sessionStorage.getItem("client") == '') {
                    setShowModal(true)
                }
            }
        }
    }, [])

    useEffect(() => {
        if (sessionStorage.getItem("client") !== '') {
            setShowModal(false)
        }
    }, [sessionStorage.getItem("client")])

    useEffect(() => {
        if (props.internCards.loading === false && props.internCards.internosCards) {
            const filter = props.internCards.internosCards.map((card) => {
                if (card.bank_id.includes("DEBIN")) {

                    const cardNoReversal = card.cardsInfo.filter((cards: any) => {
                        return !(cards.key.includes("REVERSADAS") || cards.key.includes("REVERSOS") || cards.key.includes("MERCADOPAGO") || cards.key.includes("COELSA") || cards.key.includes("APACHE") || cards.key.includes("MAU") || cards.key.includes("AVG-TIME") || cards.key.includes("LIFESPAN"))
                    })

                    const cardMP = card.cardsInfo.filter((cards: any) => {
                        return cards.key.includes("MERCADOPAGO")
                    })
                    setCardMP(cardMP)

                    const cardAVG = card.cardsInfo.filter((cards: any) => {
                        return cards.key.includes("AVG-TIME")
                    })
                    setCardAVGTime(cardAVG)

                    const cardMauApache = card.cardsInfo.filter((cards: any) => {
                        return cards.key.includes("APACHE") || cards.key.includes("MAU")
                    })
                    setCardMauApache(cardMauApache)

                    const cardLifespan = card.cardsInfo.filter((cards: any) => {
                        return cards.key.includes("LIFESPAN")
                    })
                    setCardLifespan(cardLifespan)

                    const debinCardData = groupValues(cardNoReversal)
                    setCardInfo(debinCardData);

                    const cardReversal = card.cardsInfo.filter((card: any) => {
                        return card.key.includes("REVERSADAS")
                    })

                    const debinReversalCard = groupValues(cardReversal)
                    setCardReversalInfo(debinReversalCard);
                }
            })
        }
    }, [props.internCards.loading, props.internCards.internosCards])

    useEffect(() => {
        if (bank_id_category !== 'ADMIN') {
            if (props.changeState.loading == false && props.changeState.changeState) {
                if (props.changeState.changeState[0].service_state == "inactive") {
                    setInactive(true)
                } else {
                    if (props.clientMaintenance.loading == false && props.clientMaintenance.clientMaintenance &&
                        props.clientMaintenance.clientMaintenance[0].service_maintenance) {
                        setMaintenance(true)
                    }
                }
            }
        } else {
            if (props.clientMaintenance.loading == false && props.clientMaintenance.clientMaintenance && sessionStorage.getItem("client") && sessionStorage.getItem("client") != '') {
                const maintenance = props.clientMaintenance.clientMaintenance.filter(client => client.service_name == sessionStorage.getItem("client"))[0].service_maintenance
                if (maintenance) {
                    setMaintenance(true)
                } else {
                    setMaintenance(false)
                }
            }
        }
    }, [props.changeState.loading, props.changeState.changeState, props.clientMaintenance.loading, props.clientMaintenance.clientMaintenance, sessionStorage.getItem("client")])

    useEffect(() => {
        if (props.internGraphics.loading === false && props.internGraphics.internosGraphics) {

            const entities = props.internGraphics.internosGraphics.filter((graphic) => {
                return (graphic.service_name == 'DEBIN' && graphic.intern_title.includes("MERCADOPAGO"))
            })
            setEntitiGraphic(entities)

            const filterGraphics = props.internGraphics.internosGraphics.filter((graphic) => {
                return (graphic.service_name == 'DEBIN')
            })
            setGraphicsInfo([filterGraphics])

            const filterGeneralGraphic = props.internGraphics.internosGraphics.filter((graphic) => {
                return (graphic.service_name == 'DEBIN' && graphic.intern_title.includes("REVERSO"))
            })
            setGraphicReversoGeneral(filterGeneralGraphic)

            const filterCoelsaGraphics = props.internGraphics.internosGraphics.filter((graphic) => {
                return (graphic.service_name == 'DEBIN' && graphic.intern_title.includes("COELSA"))
            })
            setCoelsaGraphics(filterCoelsaGraphics)

            const filterTiempoJPOSaB24Graphics = props.internGraphics.internosGraphics.filter((graphic) => {
                return (graphic.service_name == 'DEBIN' && graphic.intern_title.includes("AVG-TIME"))
            })
            setTiemposGraphic(filterTiempoJPOSaB24Graphics)

            const filterApacheGraphics = props.internGraphics.internosGraphics.filter((graphic) => {
                return (graphic.service_name == 'DEBIN' && graphic.intern_title.includes("APACHE"))
            })
            setApacheGraphic(filterApacheGraphics)

            const filterLifespanGraphics = props.internGraphics.internosGraphics.filter((graphic) => {
                return (graphic.service_name == 'DEBIN' && graphic.intern_title.includes("LIFESPAN"))
            })
            setLifespanGraphic(filterLifespanGraphics)
        }
    }, [props.internGraphics.loading, props.internGraphics.internosGraphics])

    if (inactive && bank_id_category !== 'ADMIN') {
        return <Redirect to="/page/inactive" />
    }

    if (maintenance && bank_id_category !== 'ADMIN') {
        return <Redirect to="/page/maintenance" />
    }

    const doHasMessages = (bool: boolean) => {
        setHasMessages(bool)
    }

    const handleOpenMessage = () => {
        setScrolling(false)
    }

    const notifIconColor = (bool: boolean) => {
        setNotifColor(bool)
    }

    const showIcon = (e: any) => {
        const iconElement: any = document.getElementById("icon-scrolling")
        const iconMsgElement: any = document.getElementById("icon-msg-scrolling")
        const iconElementCircle: any = document.getElementById("icon-scrolling-circle")
        const iconMsgElementCircle: any = document.getElementById("icon-msg-scrolling-circle")

        if (e.target.scrollTop < 100) {
            iconElement.style.display = "none"
            iconElementCircle.style.display = "none"
        } else {
            iconElement.style.display = "block"
            iconElementCircle.style.display = "block"
        }
        if (hasMessages) {
            if (e.target.scrollTop < 100) {
                iconMsgElement.style.display = "none"
                iconMsgElementCircle.style.display = "none"
            } else {
                iconMsgElement.style.display = "block"
                iconMsgElementCircle.style.display = "block"
            }
        } else {
            iconMsgElement.style.display = "none"
            iconMsgElementCircle.style.display = "none"
        }
    }


    const handleClickUp = () => {
        const contentElement = document.getElementById("ion-content-main")
        contentElement?.scrollIntoView({ behavior: "smooth" })
    }

    const showModalFunc = () => {
        setShowModal(true)
    }

    const closeModalFunc = () => {
        setShowModal(false)
    }

    const selectClient = (clientName: string, categoryName: string) => {
        sessionStorage.setItem("client", clientName)
        sessionStorage.setItem("category", categoryName)
        setClientSelected(clientName)
        setCategorySelected(categoryName)
    }

    const getCardByTitleKey = (cardsArray: any, element: any) => {
        let card = cardsArray.find(
            (arrayElement: any) => element.titleKey === arrayElement.titleKey
        );

        if (!card && element.titleKey) {
            card = { titleKey: element.titleKey };
        }
        return card;
    };

    const getCardIndex = (cardsArray: any, element: any) => {
        return cardsArray.findIndex(
            (arrayElement: any) => element.titleKey === arrayElement.titleKey
        );
    };

    const groupValues = (array: any[]): any => {
        const graphicsArray: any[] = [];
        array.forEach((element) => {

            try {
                let card = getCardByTitleKey(graphicsArray, element);
                let index = getCardIndex(graphicsArray, element);

                if (element.key.includes("Aprobadas") || element.key.includes("APROBADAS")) {
                    card.appKey = element.key;
                    card.appVal = element.value;
                    card.appAlert = element.alert;

                } else {
                    card.cantKey = element.key;
                    card.cantVal = element.value;
                    card.cantAlert = element.alert;
                }

                card.gap = element.timeGap;

                if (index === -1) {
                    graphicsArray.push(card);
                } else {
                    graphicsArray[index] = card;
                }
            } catch (err) {
                console.log(err)
            }
        });

        return graphicsArray;
    };


    const renderCardsGraphics = (titleCardGraphic: string, graphicInfo: any, type: string, card?: any) => {
        return (
            <DebinCardGraphics
                titleCardGraphic={titleCardGraphic}
                graphicInfo={graphicInfo}
                type={type}
                cardData={card}
            />
        )
    }

   const renderButton = (cardAVGTime : any, title: string) => {
        return(
            <DebinButton buttonInfo={cardAVGTime} title={title}></DebinButton>
        )
    }

    return (
        <IonPage>
            <Header timer headerText='Debin' parentCallback={(bank_id_category == "ADMIN") && showModalFunc} />
            {
                props.internCards && props.internGraphics && cardInfo && cardInfo.length > 0 && graphicsInfo && graphicsInfo.length > 0 ?
                    <IonContent fullscreen onScroll={(e) => { setScrolling(true); showIcon(e) }}>
                        <IonGrid id="ion-content-main">
                            {
                                (bank_id_category == "ADMIN") && window.screen.width < 768 &&
                                <IonRow>
                                    <IonCol className="ion-no-padding" onClick={() => showModalFunc()}>
                                        <IonCard className="ion-no-margin">
                                            <IonText><h5 className="ion-text-center ion-no-margin ion-padding">Seleccionar cliente</h5></IonText>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                            }
                            {
                                maintenance && (bank_id_category == "ADMIN") ?
                                    <MaintenanceComponent />
                                    :
                                    <IonCol>
                                        <MessagesPrisma scrolling={scrolling} hasMessages={doHasMessages} handleOpenMsg={handleOpenMessage} notifColor={notifIconColor} />
                                        <div className="debin-card">
                                            {
                                                graphicsInfo && cardInfo && cardInfo.length > 0 && graphicsInfo.length > 0 &&
                                                cardInfo.map((cardData: any) => {
                                                    return (

                                                        <DebinCard
                                                            key={cardData.titleKey}
                                                            cardData={cardData}
                                                            graphicsData={graphicsInfo}
                                                            reversalData={cardReversalInfo}
                                                        />

                                                    )
                                                })
                                            }
                                        </div>
                                        <IonRow>
                                            <IonCol sizeXl="6" sizeLg="6" sizeMd="12" sizeXs='12'>
                                                {
                                                    props.internGraphics.internosGraphics &&
                                                    renderCardsGraphics("REVERSO DEBIN GENERAL", graphicReversoGeneral, "reversedGeneral")
                                                }
                                            </IonCol>
                                            <IonCol sizeXl="6" sizeLg="6" sizeMd="12" sizeXs='12'>
                                                {
                                                    props.internGraphics.internosGraphics &&
                                                    renderCardsGraphics("COELSA TIME OUT", coelsaGraphics, "combined")
                                                }
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol sizeXl="4" sizeLg="6" sizeMd="12" sizeXs='12'>
                                                {
                                                    props.internGraphics.internosGraphics &&
                                                    renderCardsGraphics("TIEMPO JPOS a B24", tiemposGraphic, "tiempoJPOS")
                                                }
                                            </IonCol>
                                            <IonCol sizeXl="4" sizeLg="6" sizeMd="12" sizeXs='12'>
                                                {
                                                    props.internGraphics.internosGraphics &&
                                                    renderCardsGraphics("TIEMPO REPUESTA APACHE-DEBIN-COELSA", apacheGraphic, "tiempoApache")
                                                }
                                            </IonCol>
                                            <IonCol sizeXl="4" sizeLg="6" sizeMd="12" sizeXs='12'>
                                                {
                                                    props.internGraphics.internosGraphics &&
                                                    renderCardsGraphics("LIFESPAN", lifespanGraphic, "lifespan", cardLifespan)
                                                }
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol sizeXl="6" sizeLg="6" sizeMd="12" sizeXs='12'>
                                                {
                                                    props.internCards.internosCards &&
                                                    renderButton(cardAVGTime, "AVG-TIME")
                                                }
                                            </IonCol>
                                            <IonCol sizeXl="6" sizeLg="6" sizeMd="12" sizeXs='12'>
                                                {
                                                    props.internCards.internosCards &&
                                                    renderButton(cardMauApache, "ACTIVIDAD COLA MAU - TIEMPO REPUESTA APACHE")
                                                }
                                            </IonCol>
                                        </IonRow>
                                        {
                                            props.internGraphics.internosGraphics &&
                                            renderCardsGraphics("APERTURA POR ENTIDADES", entitiesGraphic, "entities", cardMP[0])
                                        }
                                    </IonCol>
                            }
                        </IonGrid>

                        <IonIcon icon={notificationsCircle} className={scrolling ? "icon-msgs-up-circle" : "ion-hide"} id="icon-msg-scrolling-circle" onClick={() => { handleClickUp() }} />
                        <IonIcon icon={notifications} className={scrolling ? (notifColor ? "icon-msgs-up color-true" : "icon-msgs-up color-false") : "ion-hide"} id="icon-msg-scrolling" onClick={() => { handleClickUp() }} />
                        <IonIcon icon={arrowUpCircle} className={scrolling ? "icon-scroll-up-circle" : "ion-hide"} id="icon-scrolling-circle" onClick={() => { handleClickUp() }} />
                        <IonIcon icon={arrowUp} className={scrolling ? "icon-scroll-up" : "ion-hide"} id="icon-scrolling" onClick={() => { handleClickUp() }} />

                        <SwtListClientsModal parentCallback={selectClient} showModal={showModal} closeModalCallback={closeModalFunc} />
                    </IonContent>
                    : <Loading />
            }
            <Footer />
        </IonPage>
    )
}



interface StateTypes {
    session: SessionDefaultStateI;
    clientMaintenance: ClientMaintenanceDefaultStateI;
    internCards: InternosCardsDefaultStateI;
    internGraphics: InternosGraphicsDefaultStateI;
    changeState: ChangeStateDefaultStateI;
}

const mapStateProps = (state: StateTypes) => {
    return {
        session: state.session,
        clientMaintenance: state.clientMaintenance,
        internCards: state.internCards,
        internGraphics: state.internGraphics,
        changeState: state.changeState,
    }
}

interface dispatchTypes {
    getClients: () => void;
    getChangeState: () => void;
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getClients: () => dispatch(GetClientImage()),
        getChangeState: () => dispatch(GetChangeState())
    }
}

export default connect(mapStateProps, mapDispatchToProps)(DebinMainView);