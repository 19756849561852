import React, { useState } from 'react'
import { connect } from 'react-redux'
import { IonGrid, IonRow, IonCol, IonIcon, IonItem, IonText, IonCardSubtitle, IonInput, IonList, IonButton} from '@ionic/react';
import { eye, eyeOff } from 'ionicons/icons';

import { SessionDefaultStateI } from '../../store/session/SessionReducer';

import ApiRequestor from '../../api_access/ApiRequestor';

import './UserProfile.css'
import Modal from '../Modal/Modal';

interface Props extends StateTypes { }

const ChangePassword: React.FC<Props> = (props): JSX.Element => {
    const [showCurrPass, setShowCurrPass] = useState(false)
    const [showNewPass, setShowNewPass] = useState(false)
    const [showNewPassRep, setShowNewPassRep] = useState(false)
    const [passwordData, setPasswordData] = useState({ currPass: '', newPass: '', newPassRep: '' })
    const [currPass_invalid, setCurrPass_invalid] = useState({ empty: false, short: false })
    const [newPass_invalid, setNewPass_invalid] = useState({ empty: false, short: false })
    const [newPassRep_invalid, setNewPassRep_invalid] = useState({ equal: false, short: false })
    const [showModal, setShowModal] = useState(false);
    const [showModalConfirm, setShowModalConfirm] = useState(false);
    const [textModalConfirm, setTextModalConfirm] = useState('')
    const [renderVar, setRenderVar] = useState<any>(true)

    const validateCurrPass = () => {
        if (passwordData.currPass.length < 8) {
            if (passwordData.currPass.length == 0) {
                setCurrPass_invalid({
                    empty: true,
                    short: true
                })
            } else {
                setCurrPass_invalid({
                    empty: false,
                    short: true
                })
            }
        } else {
            setCurrPass_invalid({
                empty: false,
                short: false
            })
        }
    }

    const validateNewPass = () => {
        if (passwordData.newPass.length < 8) {
            if (passwordData.newPass.length == 0) {
                setNewPass_invalid({
                    empty: true,
                    short: true
                })
            } else {
                setNewPass_invalid({
                    empty: false,
                    short: true
                })
            }
        } else {
            setNewPass_invalid({
                empty: false,
                short: false
            })
        }
    }

    const validateNewPassRep = () => {
        if (passwordData.newPassRep.length < 8) {
            if (passwordData.newPassRep !== passwordData.newPass) {
                setNewPassRep_invalid({
                    equal: true,
                    short: true
                })
            } else {
                setNewPassRep_invalid({
                    equal: false,
                    short: true
                })
            }
        } else {
            if (passwordData.newPassRep !== passwordData.newPass) {
                setNewPassRep_invalid({
                    equal: true,
                    short: false
                })
            } else {
                setNewPassRep_invalid({
                    equal: false,
                    short: false
                })
            }
        }
    }

    const validForm = () => {
        let validForm = false
        if (passwordData.currPass !== passwordData.newPass && passwordData.newPass == passwordData.newPassRep) {
            if (passwordData.currPass.length >= 8 && passwordData.newPass.length >= 8 && passwordData.newPassRep.length >= 8) {
                validForm = true
            }
        }
        return validForm
    }

    const onSubmit = () => {
        if (validForm()) {
            setShowModal(true)
        } else {
            validateCurrPass()
            validateNewPass()
            validateNewPassRep()
        }
    }

    const onChangePassword = async () => {
        const accessToken = props.session.loginData.getAccessToken().getJwtToken()
        const data: any = {
            body: {
                currPass: passwordData.currPass,
                newPass: passwordData.newPass,
                accessToken: accessToken
            }
        }
        const response: any = await ApiRequestor.requestToApi('/auth/change-pass', "POST", data, "USER")
        setRenderVar(false)
        if (!response.data.message) {
            setTextModalConfirm("La contraseña se ha modificado con éxito.")
            setShowModalConfirm(true)
            clearFields()
            setRenderVar(true)
        } else {
            if (response.data.message.includes("Incorrect username or password")) {
                setTextModalConfirm("No se pudo realizar el cambio de contraseña, verifique la información ingresada.")
                setShowModalConfirm(true)
                setRenderVar(true)
            }
            if (response.data.message.includes("Attempt limit exceeded, please try after some time.")) {
                setTextModalConfirm("No se pudo realizar el cambio de contraseña, se alcanzó el número máximo de intentos. Por favor intente nuevamente en otro momento.")
                setShowModalConfirm(true)
                clearFields()
                setRenderVar(true)
            }
            if (response.data.message.toLowerCase().includes("access")) {
                setTextModalConfirm("No se pudo realizar el cambio de contraseña, intente nuevamente en su próximo inicio de sesión.")
                setShowModalConfirm(true)
                clearFields()
                setRenderVar(true)
            }
        }
    }

    const clearFields = () => {
        setPasswordData({
            currPass: '',
            newPass: '',
            newPassRep: ''
        })
    }

    const handlePasswordVisibility = (id: string) => {
        switch (id) {
            case "currPass":
                setShowCurrPass(showCurrPass ? false : true)
                break;
            case "newPass":
                setShowNewPass(showNewPass ? false : true)
                break;
            case "newPassRep":
                setShowNewPassRep(showNewPassRep ? false : true)
                break;
        }
    }

    const returnChildrenModal = (): JSX.Element => {
        return (
            <>
                <IonText><h6 className="ion-padding ion-text-center ion-margin-horizontal profile-modal-text"> ¿Está seguro que desea modificar su contraseña?</h6></IonText>
                <IonRow className="ion-text-center ion-padding-bottom">
                    <IonCol size-xl="6" size-lg="6" size-md="6">
                        <IonButton className="profile-button" fill="outline" size="small" onClick={() => { onChangePassword(); setShowModal(false) }}>Aceptar</IonButton>
                    </IonCol>
                    <IonCol size-xl="6" size-lg="6" size-md="6">
                        <IonButton className="profile-button" fill="outline" size="small" onClick={() => setShowModal(false)}>Cancelar</IonButton>
                    </IonCol>
                </IonRow>
            </>
        )
    }

    const returnCHildrenModalConfirm = (): JSX.Element => {
        return (
            <>
                <IonText><p className="ion-padding ion-text-center profile-modal-text"> {textModalConfirm}</p></IonText>
                <IonRow className="ion-text-center ion-padding-bottom">
                    <IonCol size-lg="12" size-md="12">
                        <IonButton className="profile-button" fill="outline" size="small" onClick={() => { setShowModalConfirm(false) }}>Aceptar</IonButton>
                    </IonCol>
                </IonRow>
            </>
        )
    }

    return (
        <IonGrid>
            <IonCardSubtitle className="profile-subtitle">Cambio de contraseña</IonCardSubtitle>
            <IonRow>
                <IonCol>
                    {renderVar &&
                        <IonList>
                            <IonRow>
                                <IonCol size-xl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12">
                                    <IonCardSubtitle className="">Constraseña actual</IonCardSubtitle>
                                    <IonItem lines="none" className="profile-item">
                                        <IonInput
                                            type={showCurrPass ? 'text' : 'password'}
                                            placeholder="Contraseña actual"
                                            name="currPass"
                                            className="profile-input ion-no-padding"
                                            value={passwordData.currPass}
                                            onIonChange={event => {
                                                validateCurrPass()
                                                setPasswordData({ currPass: event.detail.value!, newPass: passwordData.newPass, newPassRep: passwordData.newPassRep })
                                            }} />
                                        <IonIcon icon={showCurrPass ? eye : eyeOff} onClick={(id) => handlePasswordVisibility("currPass")} className="profile-icon-eye" />
                                    </IonItem>
                                    <IonText><p className={currPass_invalid.short ? "profile-valid-text" : "ion-hide"}> Longitud menor a 8 caracteres</p></IonText>
                                    <IonText><p className={currPass_invalid.empty ? "profile-valid-text" : "ion-hide"}> Constraseña actual está vacía</p></IonText>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size-xl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12">
                                    <IonCardSubtitle className="">Nueva contraseña</IonCardSubtitle>
                                    <IonItem lines="none" className="profile-item">
                                        <IonInput
                                            type={showNewPass ? 'text' : 'password'}
                                            placeholder="Mínimo 8 caracteres"
                                            name="newPass"
                                            className="profile-input ion-no-padding"
                                            value={passwordData.newPass}
                                            onIonChange={event => {
                                                validateNewPass()
                                                setPasswordData({ currPass: passwordData.currPass, newPass: event.detail.value!, newPassRep: passwordData.newPassRep })
                                            }} />
                                        <IonIcon icon={showNewPass ? eye : eyeOff} onClick={(id) => handlePasswordVisibility("newPass")} className="profile-icon-eye" />
                                    </IonItem>
                                    <IonText><p className={newPass_invalid.short ? "profile-valid-text" : "ion-hide"}> Longitud menor a 8 caracteres</p></IonText>
                                    <IonText><p className={newPass_invalid.empty ? "profile-valid-text" : "ion-hide"}> Nueva contraseña está vacía</p></IonText>
                                </IonCol>
                                <IonCol size-xl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12">
                                    <IonCardSubtitle className="">Repetir nueva contraseña</IonCardSubtitle>
                                    <IonItem lines="none" className="profile-item">
                                        <IonInput
                                            type={showNewPassRep ? 'text' : 'password'}
                                            placeholder="Mínimo 8 caracteres"
                                            name="newPassRep"
                                            className="profile-input ion-no-padding"
                                            value={passwordData.newPassRep}
                                            onIonChange={event => {
                                                validateNewPassRep()
                                                setPasswordData({ currPass: passwordData.currPass, newPass: passwordData.newPass, newPassRep: event.detail.value! })
                                            }} />
                                        <IonIcon icon={showNewPassRep ? eye : eyeOff} onClick={(id) => handlePasswordVisibility("newPassRep")} className="profile-icon-eye" />
                                    </IonItem>
                                    <IonText><p className={newPassRep_invalid.short ? "profile-valid-text" : "ion-hide"}> Longitud menor a 8 caracteres</p></IonText>
                                    <IonText><p className={newPassRep_invalid.equal ? "profile-valid-text" : "ion-hide"}> Las contraseñas no coinciden</p></IonText>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-margin-top profile-row-button">
                                <IonButton className="profile-button" fill="outline" onClick={onSubmit}>Modificar contraseña</IonButton>
                            </IonRow>
                        </IonList>
                    }
                </IonCol>
            </IonRow>


            <Modal
                modalChildren={
                    {
                        id: "",
                        styles: "profile-modal",
                        isOpen: showModal,
                        onDidDismiss: () => setShowModal(false),
                        children: returnChildrenModal()
                    }
                }
            />

            <Modal
                modalChildren={
                    {
                        id: "",
                        styles: "profile-modal",
                        isOpen: showModalConfirm,
                        onDidDismiss: () => setShowModalConfirm(false),
                        children: returnCHildrenModalConfirm()
                    }
                }
            />

        </IonGrid>
    )
}

//Redux state and actions dispatchers
interface StateTypes {
    session: SessionDefaultStateI,
}

const mapStateToProps = (state: StateTypes) => {
    return {
        session: state.session,
    };
};

export default connect(mapStateToProps)(ChangePassword);