import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  IonCard, IonContent, IonPage, IonGrid, IonRow, IonCol, IonCardHeader, IonCardTitle,
  IonCardContent, IonText, IonCardSubtitle, IonButton
} from "@ionic/react";

import { SessionDefaultStateI } from "../../store/session/SessionReducer";
import { CardsDefaultStateI } from "../../store/mainInfo/cardsReducer";
import { LastMessagesDefaultStateI } from "../../store/lastMessagesPrisma/lastMessagesReducer";
import { GetLastMessagesPrisma } from "../../store/lastMessagesPrisma/lastMessagesActions";
import { GetCards, GetClientImage } from "../../store/mainInfo/mainInfoActions";
import { LastMessagesType } from "../../store/lastMessagesPrisma/lastMessagesActionsTypes";
import { DateTime } from "luxon";

import Header from "../header/Header";
import Footer from '../Footer/Footer'
import Cards from "../Card/Cards";
import SwtListClientsModal from "../SwtListClientsModal/SwtListClientsModal";

import "./MessagesPrismaHistorial.css";
import Modal from "../Modal/Modal";
import Loading from "../Loading/Loading";

interface Props extends StateTypes, dispatchTypes { }

const MessagesPrismaHistorial: React.FC<Props> = (props): JSX.Element => {
  const [showModal, setShowModal] = useState(false);
  const [textModal, setTextModal] = useState("");
  const [dateModal, setDateModal] = useState<any>("");
  const [numChar, setNumChar] = useState<number>(1000);
  const [msgLength, setMsgLength] = useState<number>(100);
  const [showClientsModal, setShowClientsModal] = useState(false);
  const [clientSelected, setClientSelected] = useState('')
  const [categorySelected, setCategorySelected] = useState('');
  const [lastMessagesInfo, setLastMessagesInfo] = useState<any>([])
  const bank_id_category = props.session.loginData.getIdToken().decodePayload()["custom:category"]
  const screenWidth = window.screen.width;

  useEffect(() => {
    if (!sessionStorage.getItem("client")) {
      sessionStorage.setItem("client", '')
    }
    props.getLastMessagesPrisma();
    if (bank_id_category == "ADMIN") {
      props.getClients()
      if (sessionStorage.getItem("client") == '') {
        setShowClientsModal(true)
      }
    }
    props.getCards();
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem("client") !== '') {
      setShowClientsModal(false)
    }
  }, [sessionStorage.getItem("client"), props.lastMessages.lastMessages])


  useEffect(() => {
    let lastMessagesData: any = []
    if (bank_id_category !== 'ADMIN') {
      if (props.lastMessages.loading == false && props.lastMessages.lastMessages) {
        lastMessagesData = props.lastMessages.lastMessages[0].messages
      }
    } else {
      if (props.lastMessages.loading == false && props.lastMessages.lastMessages && sessionStorage.getItem("client") !== '') {
        let lastMessages = props.lastMessages.lastMessages.filter((message: LastMessagesType) => message.bank_id == sessionStorage.getItem("client"))
        lastMessagesData = lastMessages[0].messages
      }
    }
    setLastMessagesInfo(lastMessagesData)
  }, [sessionStorage.getItem("client"), props.lastMessages.loading, props.lastMessages.lastMessages])

  const showModalFunc = () => {
    setShowClientsModal(true)
  }

  const closeModalFunc = () => {
    setShowClientsModal(false)
  }

  const selectClient = (clientName: string, categoryName: string) => {
    sessionStorage.setItem("client", clientName)
    sessionStorage.setItem("category", categoryName)
    setClientSelected(clientName)
    setCategorySelected(categoryName)
  }

  // useEffect(() => {
  //   if (screenWidth <= 576) {
  //     setMsgLength(50);
  //     setNumChar(50);
  //   } else {
  //     if (screenWidth <= 768) {
  //       setMsgLength(110);
  //       setNumChar(110);
  //     } else {
  //       if (screenWidth <= 992) {
  //         setMsgLength(170);
  //         setNumChar(170);
  //       } else {
  //         if (screenWidth <= 1200) {
  //           setMsgLength(170);
  //           setNumChar(170);
  //         } else {
  //           setMsgLength(210);
  //           setNumChar(210);
  //         }
  //       }
  //     }
  //   }
  // }, [screenWidth]);


  const returnChildrenModal = (): JSX.Element => {
    return (
      <>
        <div id="grid-modal" className="messages-grid-modal">
          <IonButton className="messages-button-date" fill="default" size-xk="large" size-lg="large" size-md="small" size-sm="small" size-xs="small">
            {dateModal}
          </IonButton>
          <IonText>
            <h6 id="modal-text-id" className="ion-padding ion-text-center ion-margin-horizontal messages-modal-text">
              {textModal}
            </h6>
          </IonText>
          <IonButton className="messages-button" fill="outline" onClick={() => setShowModal(false)} >
            Cerrar
          </IonButton>
        </div>
      </>
    )
  }

  const openMessage = (date: any, message: string) => {
    setTextModal(message);
    setDateModal(
      DateTime.fromISO(date.split("T")[0], {
        zone: "America/Argentina/Buenos_Aires",
      }).toFormat("dd/MM/yyyy")
    );
    setShowModal(true);
  };

  return (
    <IonPage>
      <Header timer headerText="Historial de mensajes" parentCallback={(bank_id_category == "ADMIN") && showModalFunc} />
      {
        props.lastMessages.lastMessages ?
          <IonContent fullscreen>
            <IonGrid>
              {(bank_id_category == "ADMIN") && window.screen.width < 768 &&
                <IonRow>
                  <IonCol className="ion-no-padding" onClick={() => showModalFunc()}>
                    <IonCard className="ion-no-margin">
                      <IonText><h5 className="ion-text-center ion-no-margin ion-padding">Seleccionar cliente</h5></IonText>
                    </IonCard>
                  </IonCol>
                </IonRow>
              }
              {
                sessionStorage.getItem("category") !== "INTERNO" &&
                <IonRow className="messagePrismaHistorial-card-container">
                  <Cards collapsed clientSelected={sessionStorage.getItem("client")} />
                </IonRow>
              }
              <IonRow>
                <IonCard className="messages-container">
                  <IonCardHeader className="messages-container-header">
                    <IonCardTitle mode="md" className="ion-text-center messages-title-header">
                      Mensajes de los últimos 30 días
                    </IonCardTitle>
                  </IonCardHeader>
                  {lastMessagesInfo.length > 0 ?
                    lastMessagesInfo.map(
                      (message: any) => {
                        return (
                          <IonCardContent className="messages-card-content-2">
                            <IonCard key={`${message.message_swt_message}`} className={message.message_swt_close ? "messages-card" : "messages-card-false"}>
                              <IonCardContent className="messages-card-content">
                                <IonRow className="messages-card-row">
                                  <IonCol size-xl="2" size-lg="2" size-md="2" size-sm="3" size-xs="3" className={message.message_swt_close ? "messages-card-col messages-card-date" : "messages-card-col messages-card-date-false"} >
                                    <IonText className="">
                                      <p className="messages-text-date">
                                        {DateTime.fromISO(message.message_swt_created_at.split("T")[0], { zone: "America/Argentina/Buenos_Aires" }).toFormat("dd/MM/yyyy")}
                                      </p>
                                    </IonText>
                                  </IonCol>
                                  <IonCol size-xl="10" size-lg="10" size-md="10" size-sm="9" size-xs="9" className="messages-card-col messages-card-message">
                                    {message.message_swt_message.length >=
                                      msgLength ? (
                                      <IonCardSubtitle mode="md" className="messages-card-text-msg">
                                        {message.message_swt_message.slice(0, numChar)}
                                        ...
                                        <span
                                          onClick={() => openMessage(message.message_swt_created_at, message.message_swt_message)} className="messages-readmore">
                                          (Leer Más)
                                        </span>
                                      </IonCardSubtitle>
                                    ) : (
                                      <IonCardSubtitle mode="md" className="messages-card-text-msg">
                                        {message.message_swt_message}
                                      </IonCardSubtitle>
                                    )}
                                  </IonCol>
                                </IonRow>
                              </IonCardContent>
                            </IonCard>
                          </IonCardContent>
                        );
                      }
                    ) :
                    <h5 className="message-history ion-text-center">No hay mensajes para mostrar</h5>
                  }
                </IonCard>
              </IonRow>

              <Modal
                modalChildren={
                  {
                    id: "modal-msgs",
                    styles: "messages-modal",
                    isOpen: showModal,
                    onDidDismiss: () => setShowModal(false),
                    children: returnChildrenModal()
                  }
                }


              />

            </IonGrid>
            <SwtListClientsModal parentCallback={selectClient} showModal={showClientsModal} closeModalCallback={closeModalFunc} />
          </IonContent>

          :
          <Loading />

      }
      <Footer />
    </IonPage>
  );
};

//Redux state and actions dispatchers

interface StateTypes {
  session: SessionDefaultStateI;
  cards: CardsDefaultStateI;
  lastMessages: LastMessagesDefaultStateI;
}

const mapStateToProps = (state: StateTypes) => {
  return {
    session: state.session,
    cards: state.cards,
    lastMessages: state.lastMessages,
  };
};

interface dispatchTypes {
  getLastMessagesPrisma: () => void;
  getCards: () => void;
  getClients: () => void;
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getLastMessagesPrisma: () => dispatch(GetLastMessagesPrisma()),
    getCards: () => dispatch(GetCards()),
    getClients: () => dispatch(GetClientImage()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MessagesPrismaHistorial);