import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  IonContent, IonPage, IonGrid, IonRow, IonCard, IonCardHeader, IonText, IonCardContent, getPlatforms
} from "@ionic/react";
import { ChangeStateDefaultStateI } from "../../store/changeState/changeStateReducer";
import { SessionDefaultStateI } from "../../store/session/SessionReducer";

import { GetChangeState } from "../../store/changeState/changeStateActions";

import Header from "../header/Header";
import Footer from "../Footer/Footer";
import CardClient from './CardClient';

import "./ChangeState.css";
import Loading from "../Loading/Loading";


interface Props extends StateTypes, dispatchTypes { }

const ChangeState: React.FC<Props> = (props): JSX.Element => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const desktop = getPlatforms().includes("desktop")

  useEffect(() => {

    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
      setShouldRefresh(true);
    }

    if(desktop){
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
    
  }, []);

  useEffect(() => {
    if (shouldRefresh) {
      setShouldRefresh(false);
      window.location.reload();
    }
  }, [shouldRefresh]);

  useEffect(() => {
    if (props.session.loading === false && props.session.loginData) {
      props.getChangeState();
    }
  }, []);

  return (
    <IonPage>
      <Header headerText="Estado de Clientes" />
      {
        props.changeState.loading === false && props.changeState.changeState ?
          <IonContent fullscreen>
            <IonCard className="col-client-change-container ion-margin">
              <IonCardHeader className="change-client-container-header">
                <IonText>
                  <h5 className="ion-no-margin ion-no-padding change-container-title">Servicios Activos e Inactivos</h5>
                </IonText>
              </IonCardHeader>
              <IonCardContent >
                <IonGrid>
                  <IonRow>
                    {
                      props.changeState.changeState.map((client: any, i: number) => {
                        return (
                          <CardClient
                            serviceId={client.service_id}
                            serviceName={client.service_name}
                            serviceState={client.service_state}
                            key={client.service_name} />
                        )
                      })
                    }
                  </IonRow>
                </IonGrid>
              </IonCardContent>
            </IonCard>
          </IonContent>
          :
          <Loading />
      }
      <Footer />
    </IonPage>
  );
};

//Redux state and actions dispatchers
interface StateTypes {
  session: SessionDefaultStateI;
  changeState: ChangeStateDefaultStateI;
}

const mapStateToProps = (state: StateTypes) => {
  return {
    session: state.session,
    changeState: state.changeState
  };
};

interface dispatchTypes {
  getChangeState: () => void;
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getChangeState: () => dispatch(GetChangeState())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeState);