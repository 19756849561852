import React, { useState, useEffect } from "react";
import { IonCard, IonCardHeader, IonCardTitle, IonIcon, IonCardContent, IonCol } from '@ionic/react';
import { ellipse } from "ionicons/icons";

import ToggleChangeState from "./ToggleChangeState"
import ApiRequestor from "../../api_access/ApiRequestor";

import "./ChangeState.css";

interface Props {
  serviceId: number
  serviceName: string,
  serviceState: string,
}

const CardClient: React.FC<Props> = (props): JSX.Element => {

  const [check, setCheck] = useState(props.serviceState === 'active' ? true : false);
  const [showModalInactive, setShowModalInactive] = useState<boolean>(false);

  useEffect(() => {
    if (props.serviceState === 'active') {
      setCheck(true);
    } else {
      setCheck(false);
    }
  }, [props.serviceState])

  const checkToggle = async (check: boolean) => {

    let data: any = {
      body: {
        service_id: props.serviceId,
        service_name: props.serviceName,
        service_state: check ? 'active' : 'inactive',
      }
    }

    const response = await ApiRequestor.requestToApi('/swt-admin/clients-state', "POST", data);
    if (response && response.status === 200) {
      setShowModalInactive(false)
      setCheck(check);
      sessionStorage.setItem("client", '');
    }
  }

  return (
    <IonCol
      className=""
      sizeLg="3"
      sizeMd="4"
      sizeSm="6"
      sizeXs="6">
      <IonCard className="client-change">
        <IonCardHeader className="client-change-header">
          {
            check ?
              <IonIcon icon={ellipse} className="card-status-icon change-status-icon-checked client-change-icon-header" />
              :
              <IonIcon icon={ellipse} className="card-status-icon change-status-icon-not-checked client-change-icon-header" />
          }
          <IonCardTitle className="client-change-title">
            {props.serviceName}
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent className="client-change-content-alignment">
          <IonCardTitle className="client-change-content-title">
            Estado del Servicio
          </IonCardTitle>
          <ToggleChangeState
            serviceName={props.serviceName}
            check={check}
            parentCallback={checkToggle}
            ModalInactive={() => setShowModalInactive(false)}
            ModalActive={() => setShowModalInactive(true)}
            showModalInactive={showModalInactive}
          />
        </IonCardContent>
      </IonCard>
    </IonCol>
  );
};


export default CardClient;
