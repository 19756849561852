import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    IonButton, IonCard, IonCardTitle, IonCol, IonContent, IonGrid, IonIcon, IonInput,
    IonItem, IonLabel, IonRow, IonText
} from '@ionic/react';
import { checkmarkCircleOutline, closeCircleOutline } from 'ionicons/icons';

import { GetABMUsers } from '../../store/ABMUsers/ABMUsersActions';

import ApiRequestor from '../../api_access/ApiRequestor';

import './ABMUsers.css';
import Modal from '../Modal/Modal';

interface Data {
    username: string,
    bank_id: string,
    first_name: string,
    last_name: string,
    phone_number: string,
    email: string,
    category: string,
    notifications?: any,
}

interface Props extends StateTypes, dispatchTypes {
    userData: Data;
    backToTable: () => void
}

const ModifyABMUsers: React.FC<Props> = (props): JSX.Element => {

    const [dataUser, setDataUser] = useState({
        username: '',
        bank_id: '',
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        category: '',
    });
    const [nameInvalid, setNameInvalid] = useState(false);
    const [lastnameInvalid, setLastnameInvalid] = useState(false);
    const [phoneInvalid, setPhoneInvalid] = useState(false);
    const [emailInvalid, setEmailInvalid] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [textModal, setTextModal] = useState('');
    const regularExpressionEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const regularExpressionPhone = /^\+(?=.*549)\d{13}$/;


    useEffect(() => {
        setDataUser({
            username: props.userData.username,
            bank_id: props.userData.bank_id,
            first_name: props.userData.first_name,
            last_name: props.userData.last_name,
            phone_number: props.userData.phone_number,
            email: props.userData.email,
            category: props.userData.category
        })
    }, [])

    const getDataForm = (e: any) => {
        setDataUser({
            ...dataUser,
            [e.target.name]: e.detail.value!
        })
    }

    const validationForm = () => {
        Object.keys(dataUser).forEach((key) => {
            if ((dataUser as any)[key].length < 1) {
                key == 'first_name' && setNameInvalid(true)
                key == 'last_name' && setLastnameInvalid(true)
            } else {
                key == 'first_name' && setNameInvalid(false)
                key == 'last_name' && setLastnameInvalid(false)
            }
            if (key == 'email') {
                !regularExpressionEmail.test(dataUser.email) ? setEmailInvalid(true) : setEmailInvalid(false)
            }
            if (key == 'phone_number') {
                !regularExpressionPhone.test(dataUser.phone_number) ? setPhoneInvalid(true) : setPhoneInvalid(false)
            }
        })
    }

    const modifyUser = async (e: any) => {
        e.preventDefault();
        validationForm()
        let valid = false;
        if (dataUser.first_name.length > 1 && dataUser.last_name.length > 1 &&
            regularExpressionEmail.test(dataUser.email) && regularExpressionPhone.test(dataUser.phone_number)) {
            valid = true
        }

        if (valid) {
            let data: any = {
                body: {
                    "username": dataUser.username,
                    "bank_id": dataUser.bank_id,
                    "first_name": dataUser.first_name,
                    "last_name": dataUser.last_name,
                    "phone_number": dataUser.phone_number,
                    "email": dataUser.email,
                    "category": dataUser.category
                }
            }

            const response: any = await ApiRequestor.requestToApi("/user", "PUT", data, "USER");
            if (response && response.status === 200) {
                setTextModal('Usuario Modificado Correctamente')
                setShowModal(true);
            } else {
                if (response && response.includes("status code 500")) {
                    setTextModal('No se pudo modificar el usuario. Por favor chequear los datos ingresados')
                    setShowModal(true)
                }
            }

        } else {
            validationForm()
        }
    }

    const dismissModal = () => {
        setShowModal(false);
        if (textModal.includes('Usuario Modificado Correctamente')) {
            props.getABMUsers()
            props.backToTable()
        }
    }

    return (
        <IonContent fullscreen className="ion-padding">
            <IonCard className="ABMUsersFormCard">
                <IonCardTitle className="ion-text-center ABMUsersFormTitle">
                    MODIFICAR USUARIO
                </IonCardTitle>
                <IonGrid className="ion-margin ion-no-margin-top">
                    <IonRow className='AMBUsersModifyTable'>
                        <IonCol className="ABMUsersFormCol" sizeLg='6' sizeXs='12'>
                            <IonLabel className="ion-margin-vertical ABMUsersFormLabel">
                                Nombre
                            </IonLabel>
                            <IonItem className="ABMUsersFormItem" lines='none'>
                                <IonInput
                                    type="text"
                                    placeholder="Ingrese el nombre"
                                    className="ion-padding ABMUsersFormInput"
                                    name="first_name"
                                    value={dataUser.first_name}
                                    onIonChange={(e) => getDataForm(e)} />
                            </IonItem>
                            <IonText>
                                <p className={nameInvalid ? "login-valid-text" : "ion-hide"}>Longitud menor a 2 caracteres</p>
                            </IonText>
                        </IonCol>
                        <IonCol sizeLg='6' sizeXs='12' className="ABMUsersFormCol">
                            <IonLabel className="ion-margin-vertical ABMUsersFormLabel">
                                Apellido
                            </IonLabel>
                            <IonItem className="ABMUsersFormItem" lines='none'>
                                <IonInput
                                    type="text"
                                    placeholder="Ingrese el apellido"
                                    className="ion-padding ABMUsersFormInput"
                                    name="last_name"
                                    value={dataUser.last_name}
                                    onIonChange={(e) => getDataForm(e)}>
                                </IonInput>
                            </IonItem>
                            <IonText>
                                <p className={lastnameInvalid ? "login-valid-text" : "ion-hide"}>Longitud menor a 2 caracteres</p>
                            </IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className="ABMUsersFormCol" sizeLg='6' sizeXs='12'>
                            <IonLabel className="ion-margin-vertical ABMUsersFormLabel">
                                Username
                            </IonLabel>
                            <IonItem className="ABMUsersFormItem" lines='none'>
                                <IonInput
                                    type="text"
                                    placeholder="Ingrese el username"
                                    className="ion-padding ABMUsersFormInput"
                                    name="username"
                                    value={dataUser.username}
                                    disabled />
                            </IonItem>
                        </IonCol>
                        <IonCol className="ABMUsersFormCol" sizeLg='6' sizeXs='12'>
                            <IonLabel className="ion-margin-vertical ABMUsersFormLabel">
                                Email
                            </IonLabel>
                            <IonItem className="ABMUsersFormItem" lines='none'>
                                <IonInput
                                    type="text"
                                    placeholder="Ingrese el email"
                                    className="ion-padding ABMUsersFormInput"
                                    name="email"
                                    value={dataUser.email}
                                    onIonChange={(e) => getDataForm(e)}
                                    disabled />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className="ABMUsersFormCol" sizeLg='6' sizeXs='12'>
                            <IonLabel className="ion-margin-vertical ABMUsersFormLabel">
                                Servicio
                            </IonLabel>
                            <IonItem className="ABMUsersFormItem" lines='none'>
                                <IonInput
                                    type="text"
                                    placeholder="Seleccione el servicio"
                                    className="ion-padding ABMUsersFormInput"
                                    name="bank_id"
                                    value={dataUser.bank_id}
                                    disabled />
                            </IonItem>
                        </IonCol>
                        <IonCol className="ABMUsersFormCol" sizeLg='6' sizeXs='12'>
                            <IonLabel className="ion-margin-vertical ABMUsersFormLabel">
                                Categoría
                            </IonLabel>
                            <IonItem className="ABMUsersFormItem" lines='none'>
                                <IonInput
                                    type="text"
                                    placeholder="Seleccione la categoría"
                                    className="ion-padding ABMUsersFormInput"
                                    name="bank_id"
                                    value={dataUser.category}
                                    disabled />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className="ABMUsersFormCol" sizeLg='6' sizeXs='12'>
                            <IonLabel className="ion-margin-vertical ABMUsersFormLabel">
                                Número de Teléfono
                            </IonLabel>
                            <IonItem className="ABMUsersFormItem" lines='none'>
                                <IonInput
                                    type="text"
                                    placeholder="Ingrese el número de teléfono"
                                    className="ion-padding ABMUsersFormInput"
                                    name="phone_number"
                                    value={dataUser.phone_number}
                                    onIonChange={(e) => getDataForm(e)} />
                            </IonItem>
                            <IonText>
                                <p className={phoneInvalid ? "login-valid-text" : "ion-hide"}>Debe ser de la forma +549 más 10 dígitos</p>
                            </IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ABMUsers-container-button ion-margin-horizontal ion-margin-top ion-no-margin-bottom ion-justify-content-center">
                        <IonButton type="submit" fill="clear" onClick={modifyUser}>
                            <IonIcon icon={checkmarkCircleOutline} color="success" className="ion-padding ion-no-padding-bottom AMBUsersButton" />
                        </IonButton>
                        <IonButton fill="clear" onClick={props.backToTable}>
                            <IonIcon icon={closeCircleOutline} color="danger" className="ion-padding ion-no-padding-bottom AMBUsersButton" />
                        </IonButton>
                    </IonRow>
                </IonGrid>
            </IonCard>

            <Modal
                modalConfirmation={{
                    textConfirmation: textModal,
                    onDidDimissConfirmModal: dismissModal,
                    isOpenModalConfirm: showModal,
                    onClickConfirmModal: dismissModal,
                }}
            />

        </IonContent>
    );
}

interface StateTypes {
}

const mapStateProps = (state: StateTypes) => {
    return {}
}

interface dispatchTypes {
    getABMUsers: () => void;
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getABMUsers: () => dispatch(GetABMUsers())
    };
}

export default connect(mapStateProps, mapDispatchToProps)(ModifyABMUsers);