import React, { useEffect, useState } from 'react';
import { IonItem, IonText, IonToggle } from '@ionic/react';

import './ABMPrismaMessages.css';

interface Props {
  text: string,
  id: string,
  check: boolean,
  parentCallback: (check: boolean, id: string) => void,
  disabled?: boolean
}

const TogglePrismaMessages: React.FC<Props> = ({ text, id, check, parentCallback, disabled }) => {
  const [checked, setChecked] = useState<boolean>(check)

  useEffect(() => {
    if (disabled !== undefined && disabled == false) {
      setChecked(check)
    }
  }, [disabled])

  const handleChange = (e: any) => {
    setChecked(!checked)
    parentCallback(!checked, id)
  }

  return (
    <IonItem lines="none" className="toggle-ABMPrismaMessages-item">
      <IonText className="ion-text-wrap ion-text-start toggle-ABMPrismaMessages-text">{text}</IonText>
      {
        disabled ?
          <IonToggle color="secondary" checked={true} disabled slot="start" mode="ios" className="toggle-ABMPrismaMessages" id={id} />
          :
          <IonToggle color="secondary" checked={checked} onIonChange={handleChange} slot="start" mode="ios" className="toggle-ABMPrismaMessages" id={id} />
      }
    </IonItem>
  );
};

export default TogglePrismaMessages;