import { IonCard, IonCardContent, IonCol, IonContent, IonImg, IonItem, IonLabel, IonPage, IonRow, IonSpinner, IonText, IonThumbnail } from "@ionic/react"
import './Loading.css'

import prismaIso from '../../assets/prismaIso.png'
import prismaIsoDark from '../../assets/prismaIsoDark.png'
import prismaLogo from '../../assets/prismaLogo.png'
import prismaLogoDark from '../../assets/prismaLogoDark.png'
import { useEffect, useState } from "react"


const Loading: React.FC = (): JSX.Element => {

    const [image, setImage] = useState<string>('');

    const styleSelected: any = localStorage.getItem("styleMode");


    useEffect(()=>{
        if(window.screen.width < 768){
            styleSelected == 'dark' ? setImage(prismaIsoDark) : setImage(prismaIso)
        }else{
            styleSelected == 'dark' ? setImage(prismaLogoDark) : setImage(prismaLogo)
        }
    },[window.screen.width])


    return (
        <IonCol className="content-loading">
            <IonRow>
                <IonImg className="ion-image-loading" src={image} alt="loading" />
            </IonRow>
            <IonRow>
                <IonText className="ion-text-loading">Aguarde mientras cargamos la información</IonText>
            </IonRow>
            <IonRow>
                <IonSpinner  duration={1000} className="ion-spinner-loading" name="dots"></IonSpinner>
            </IonRow>
        </IonCol>
    )
}

export default Loading;