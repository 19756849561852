import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { IonButton, IonCard, IonCardTitle, IonCol, IonContent, IonGrid, IonRow, IonText } from '@ionic/react';

import { GetClientNotifications } from '../../store/clientNotifications/clientNotificationsActions';
import { SessionDefaultStateI } from '../../store/session/SessionReducer';
import { ClientNotificationsDefaultStateI } from '../../store/clientNotifications/clientNotificationsReducer';

import TableABMPrismaMessages from './TableABMPrismaMessages';

import './ABMPrismaMessages.css';

interface Props extends StateTypes, dispatchTypes {
    backToCreate: () => void
    renderModify: (messageData: any) => void
}

const ABMPrismaMessages: React.FC<Props> = (props): JSX.Element => {

    useEffect(() => {
        if (props.session.loading === false && props.session.loginData) {
            props.getClientNotifications();
        }
    }, [])

    return (
        <IonContent fullscreen>
            <IonGrid id="ion-content-main">
                <IonCard className="ion-padding ABMPrismaMessagesTable">
                    <IonCardTitle className="ion-text-center ion-padding ABMClientsFormTitle">
                        Mensajes Activos
                    </IonCardTitle>
                    <IonCard className="ABMPrismaMessagesCardTable">
                        {props.clientNotifications.clientNotifications &&
                            props.clientNotifications.clientNotifications.length > 0 ?
                            <IonGrid className="ABMPrismaMessagesGrid">
                                <IonRow className='mobile-hidden-messages'>
                                    <IonCol className='ABMPrismaMessagesCol ABMPrismaMessagesColTitle' sizeLg="2.5">
                                        <IonText>Cliente/s</IonText>
                                    </IonCol>
                                    <IonCol className='ABMPrismaMessagesCol ABMPrismaMessagesColTitle' sizeLg="4">
                                        <IonText>Mensaje</IonText>
                                    </IonCol>
                                    <IonCol className='ABMPrismaMessagesCol ABMPrismaMessagesColTitle' sizeLg="2.5">
                                        <IonText>Expiración</IonText>
                                    </IonCol>
                                    <IonCol className='ABMPrismaMessagesCol ABMPrismaMessagesColTitle' sizeLg="1.5">
                                        <IonText>Editar</IonText>
                                    </IonCol>
                                    <IonCol className='ABMPrismaMessagesCol ABMPrismaMessagesColTitle' sizeLg="1.5">
                                        <IonText>Eliminar</IonText>
                                    </IonCol>
                                </IonRow>

                                {props.clientNotifications.clientNotifications.map((client: any) => {
                                    return (
                                        <TableABMPrismaMessages
                                            key={client.message_swt_id}
                                            messagesData={client}
                                            renderModify={props.renderModify}/>
                                    )
                                })
                                }
                            </IonGrid>
                            :
                            <IonCardTitle className="ion-text-center ion-padding msjActivos">
                                No Hay Mensajes Activos
                            </IonCardTitle>
                        }
                    </IonCard>
                    <IonRow className="ion-padding ion-justify-content-center">
                        <IonButton className="ion-margin ABMPrismaMessagesButtonSimple" fill="outline" onClick={props.backToCreate}>
                            Volver Atrás
                        </IonButton>
                    </IonRow>
                </IonCard>
            </IonGrid>
        </IonContent>
    )
}

//Redux state and actions dispatchers
interface StateTypes {
    clientNotifications: ClientNotificationsDefaultStateI,
    session: SessionDefaultStateI,

}

const mapStateToProps = (state: StateTypes) => {
    return {
        clientNotifications: state.clientNotifications,
        session: state.session,
    };
};

interface dispatchTypes {
    getClientNotifications: () => void
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getClientNotifications: () => dispatch(GetClientNotifications())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ABMPrismaMessages);