import React, { useEffect, useState } from "react";
import { IonPage, IonCard, IonCardHeader, IonCardContent, IonIcon, IonButton, IonHeader, IonImg, IonFooter, getPlatforms } from '@ionic/react';
import { warning } from "ionicons/icons";

import { setStyleMode } from "../../utils/StyleUtils";

import Footer from "../Footer/Footer";
import prismaLogo from '../../assets/prismaLogo.png'
import prismaLogoDark from '../../assets/prismaLogoDark.png'

import "./InactivePage.css";

const InactivePage: React.FC<any> = (): JSX.Element => {
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
      });
      const [shouldRefresh, setShouldRefresh] = useState(false);
      const desktop = getPlatforms().includes("desktop")

      useEffect(() => {
    
        function handleResize() {
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight
          });
          setShouldRefresh(true);
        }
    
        if(desktop){
          window.addEventListener('resize', handleResize);
          return () => window.removeEventListener('resize', handleResize);
        }
        
      }, []);
    
      useEffect(() => {
        if (shouldRefresh) {
          setShouldRefresh(false);
          window.location.reload();
        }
      }, [shouldRefresh]);

    useEffect(() => {
        setStyleMode()
    }, [])

    const logout = () => {
        window.location.href = "/page/logout";
    }

    return (
        <IonPage className="inactive-container">
            {localStorage.getItem("styleMode") == "dark" ?
                <IonHeader className="inactive-header">
                    <IonImg id="logoDark" className="inactive-img-logo" src={prismaLogoDark} />
                </IonHeader>
                :
                localStorage.getItem("styleMode") == "light" ?
                    <IonHeader className="inactive-header">
                        <IonImg id="logoLight" className="inactive-img-logo" src={prismaLogo} />
                    </IonHeader>
                    :
                    <IonHeader className="inactive-header" />
            }
            <IonCard className="inactive-card">
                <IonCardHeader className="inactive-card-header">
                    <IonIcon className="inactive-card-icon" icon={warning} />
                </IonCardHeader>
                <IonCardContent className="inactive-card-text">
                    Disculpe las molestias, el Cliente se encuentra Inactivo.
                </IonCardContent>
                <IonCardContent className="inactive-card-text">
                    <IonButton fill="outline" className="inactive-button" onClick={logout}> VOLVER AL INICIO</IonButton>
                </IonCardContent>
            </IonCard>
            <IonFooter className="inactive-footer">
                <Footer/>
            </IonFooter>
        </IonPage>
    );
}

export default InactivePage;