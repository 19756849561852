import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IonButton, IonCard, IonCardSubtitle, IonCol, IonContent, IonGrid, IonIcon, IonPage, IonRow, IonSpinner, IonText, IonTitle, getPlatforms } from '@ionic/react';
import { addCircleOutline, eye, pencil, peopleCircle, trash } from 'ionicons/icons';

import { ABMKpisGroupsDefaultStateI } from '../../store/ABMMetric/ABMKpisGroups/ABMKpisGroupsReducer';
import { GetABMKpisGroups } from '../../store/ABMMetric/ABMKpisGroups/ABMKpisGroupsActions';
import { ABMInternDefaultStateI } from '../../store/ABMMetric/ABMIntern/ABMInternReducer';
import { GetABMIntern } from '../../store/ABMMetric/ABMIntern/ABMInternActions';

import ApiRequestor from '../../api_access/ApiRequestor';
import Header from '../header/Header';
import SwtListClientsModal from '../SwtListClientsModal/SwtListClientsModal';
import Footer from '../Footer/Footer';
import AddGroupABMKpis from './AddGroupABMKpis';
import ModifyGroupsABMKpis from './ModifyGroupsABMKpis';
import SeeABMKpis from './SeeABMKpis';
import AddABMIntern from './AddABMIntern';
import ModifyABMIntern from './ModifyABMIntern';

import './ABMMetric.css';
import Modal from '../Modal/Modal';
import Loading from '../Loading/Loading';

interface DataModify {
    kpi_group_id: number,
    kpi_group_name: string,
    kpi_group_created_by: string,
    kpi_group_updated_by: string,
    service_id: number,
    service_name: string
}

interface InternDataModify {
    intern_id: number;
    intern_name: string;
    intern_host: string;
    intern_field: string;
    intern_type: string;
    intern_transform: boolean;
    intern_endpoint_id: string;
    intern_threshold: boolean;
    intern_title: string;
    intern_title_key: string;
    intern_time_gap: number;
    intern_linechart_label: string;
    intern_category: string;
    intern_graphic: boolean;
    intern_created_by: string,
    intern_updated_by: string,
    service_name: string;
}

interface Props extends StateTypes, dispatchTypes { }

const ABMMetricMainView: React.FC<Props> = (props): JSX.Element => {

    const [showModalRemove, setShowModalRemove] = useState(false);
    const [showModalRemoveIntern, setShowModalRemoveIntern] = useState(false);
    const [dataForDelete, setDataForDelete] = useState({
        name: '',
        id: 0
    })
    const [showModalExit, setShowModalExit] = useState(false);
    const [showModalExitIntern, setShowModalExitIntern] = useState(false);
    const [showSipnner, setShowSpinner] = useState(false)


    // VERIFICAR
    const [showSipnnerIntern, setShowSpinnerIntern] = useState(false)
    const [clientSelected, setClientSelected] = useState('');
    const [categorySelected, setCategorySelected] = useState('');
    const [clientSelect, setClientSelect] = useState<any>('');
    const [kpisGroups, setKpisGroups] = useState<any>([])
    const [internData, setInternData] = useState<any>([])
    const [showModal, setShowModal] = useState(false)
    const [renderViews, setRenderViews] = useState({
        renderKpiGroupsTable: true,
        renderKpiGroupsCreate: false,
        renderKpiGroupsModify: false,
        renderKpisTable: false,
        renderInternCreate: false,
        renderInternModify: false,
    })
    const [dataToModify, setDataToModify] = useState<DataModify>({
        kpi_group_id: 0,
        kpi_group_name: '',
        kpi_group_created_by: '',
        kpi_group_updated_by: '',
        service_id: 0,
        service_name: ''
    })
    const [dataInternToModify, setDataInternToModify] = useState<InternDataModify>({
        intern_id: 0,
        intern_name: '',
        intern_host: '',
        intern_field: '',
        intern_type: '',
        intern_transform: true,
        intern_endpoint_id: '',
        intern_threshold: true,
        intern_title: '',
        intern_title_key: '',
        intern_time_gap: 0,
        intern_linechart_label: '',
        intern_category: '',
        intern_graphic: true,
        intern_created_by: '',
        intern_updated_by: '',
        service_name: '',
    })

    interface SeeDataIntern {
        show: boolean,
        internData: InternDataModify
    }

    const [modalSee, setModalSee] = useState<SeeDataIntern>({
        show: false,
        internData: {
            intern_id: 0,
            intern_name: '',
            intern_host: '',
            intern_field: '',
            intern_type: '',
            intern_transform: true,
            intern_endpoint_id: '',
            intern_threshold: true,
            intern_title: '',
            intern_title_key: '',
            intern_time_gap: 0,
            intern_linechart_label: '',
            intern_category: '',
            intern_graphic: true,
            intern_created_by: '',
            intern_updated_by: '',
            service_name: ''
        }
    });

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });
    const [shouldRefresh, setShouldRefresh] = useState(false);
    const desktop = getPlatforms().includes("desktop")

    useEffect(() => {
  
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight
        });
        setShouldRefresh(true);
      }
  
      if(desktop){
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }
      
    }, []);

    useEffect(() => {
        if (shouldRefresh) {
            setShouldRefresh(false);
            window.location.reload();
        }
    }, [shouldRefresh]);

    useEffect(() => {
        props.getABMKpisGroups();
        props.getABMIntern();
        if (!sessionStorage.getItem("client")) {
            sessionStorage.setItem("client", '');
        }
        if (sessionStorage.getItem("client") == '') {
            setShowModal(true);
        }
    }, []);

    useEffect(() => {
        if (sessionStorage.getItem("client") !== '') {
            setShowModal(false)
            let cliente = sessionStorage.getItem("client")
            setClientSelect(cliente)
        }
    }, [sessionStorage.getItem("client")])

    useEffect(() => {
        if (props.ABMKpisGroups.loading == false && props.ABMKpisGroups.abmkpisgroups) {
            const kpisGroupsData: any = props.ABMKpisGroups.abmkpisgroups.filter(kpisGroup => kpisGroup.service_name == sessionStorage.getItem("client"))
            setKpisGroups(kpisGroupsData)
        }
    }, [sessionStorage.getItem("client"), props.ABMKpisGroups.loading, props.ABMKpisGroups.abmkpisgroups])

    useEffect(() => {
        if (props.ABMIntern.loading == false && props.ABMIntern.abmintern) {
            const internGroupsData: any = props.ABMIntern.abmintern.filter(internGroup => internGroup.service_name == sessionStorage.getItem("client"))
            setInternData(internGroupsData)
        }

    }, [sessionStorage.getItem("client"), props.ABMIntern.loading, props.ABMIntern.abmintern])

    const backToKpisGroupTable = () => {
        setRenderViews({
            renderKpiGroupsTable: true,
            renderKpiGroupsCreate: false,
            renderKpiGroupsModify: false,
            renderKpisTable: false,
            renderInternCreate: false,
            renderInternModify: false,
        })
    }

    const createKpiGroup = () => {
        setRenderViews({
            renderKpiGroupsTable: false,
            renderKpiGroupsCreate: true,
            renderKpiGroupsModify: false,
            renderKpisTable: false,
            renderInternCreate: false,
            renderInternModify: false,
        })
    }

    const createInternMetric = () => {
        setRenderViews({
            renderKpiGroupsTable: false,
            renderKpiGroupsCreate: false,
            renderKpiGroupsModify: false,
            renderKpisTable: false,
            renderInternCreate: true,
            renderInternModify: false,
        })
    }

    const modifyKpiGroup = (kpiGroup: DataModify) => {
        setRenderViews({
            renderKpiGroupsTable: false,
            renderKpiGroupsCreate: false,
            renderKpiGroupsModify: true,
            renderKpisTable: false,
            renderInternCreate: false,
            renderInternModify: false,
        })
        setDataToModify(kpiGroup)
    }

    const modifyInternMetric = (intern: InternDataModify) => {
        setRenderViews({
            renderKpiGroupsTable: false,
            renderKpiGroupsCreate: false,
            renderKpiGroupsModify: false,
            renderKpisTable: false,
            renderInternCreate: false,
            renderInternModify: true,
        })
        setDataInternToModify(intern)
    }

    const seeKpisTable = (kpiGroup: DataModify) => {
        setRenderViews({
            renderKpiGroupsTable: false,
            renderKpiGroupsCreate: false,
            renderKpiGroupsModify: false,
            renderKpisTable: true,
            renderInternCreate: false,
            renderInternModify: false,
        })
        setDataToModify(kpiGroup)
    }

    const onDeleteKpisGroups = (kpiGroup: any) => {
        setDataForDelete({
            name: kpiGroup.name,
            id: kpiGroup.id
        })
        setShowModalRemove(true)
    }

    const onDeleteInternMetric = (intern: any) => {
        setDataForDelete({
            name: intern.name,
            id: intern.id
        })
        setShowModalRemoveIntern(true)
    }

    const deleteKpisGroups = async () => {
        setShowModalRemove(false);
        setShowSpinner(true)
        let data: any = {
            params: [
                dataForDelete.id
            ]
        }
        const response = await ApiRequestor.requestToApi('/swt-admin/kpi-groups', 'DELETE', data)
        if (response && response.status == 200) {
            setShowSpinner(false)
            setShowModalExit(true);
        }
    }

    const deleteInternMetric = async () => {
        setShowModalRemoveIntern(false);
        setShowSpinnerIntern(true)
        let data: any = {
            params: [
                dataForDelete.id
            ]
        }
        const response = await ApiRequestor.requestToApi('/swt-admin/intern', 'DELETE', data)
        if (response && response.status == 200) {
            setShowSpinnerIntern(false)
            setShowModalExitIntern(true);
        }
    }

    const onModalDismiss = () => {
        setShowModalExit(false)
        props.getABMKpisGroups();
    }

    const onModalDismissIntern = () => {
        setShowModalExitIntern(false)
        props.getABMIntern();
    }

    const selectClient = (clientName: string, categoryName: string) => {
        sessionStorage.setItem("client", clientName)
        sessionStorage.setItem("category", categoryName)
        setClientSelected(clientName)
        setCategorySelected(categoryName)
    }

    const showModalFunc = () => {
        setShowModal(true)
    }

    const closeModalFunc = () => {
        setShowModal(false)
    }


    const returnChildrenModal = (): JSX.Element => {
        return (
            <IonCard className="ABMKpisCardModal">
                <IonGrid className="">
                    <IonRow className="ion-justify-content-center">
                        <IonCardSubtitle className="cardSubtitleModal"> Detalles de la Métrica </IonCardSubtitle>
                    </IonRow>
                    <IonRow className="kpisRow">
                        <IonCol sizeXl="4" sizeXs='4.5' className='centerKpi fontSizeKpi colorKpi'>Título</IonCol>
                        <IonCol sizeXl="8" sizeXs='7.5' className='fontSizeKpi colorKpi'>{modalSee.internData.intern_title}</IonCol>
                    </IonRow>
                    <IonRow className="kpisRow">
                        <IonCol sizeXl="4" sizeXs='4.5' className='centerKpi fontSizeKpi'>Host</IonCol>
                        <IonCol sizeXl="8" sizeXs='7.5' className='fontSizeKpi'>{modalSee.internData.intern_host}</IonCol>
                    </IonRow>
                    <IonRow className="kpisRow">
                        <IonCol sizeXl="4" sizeXs='4.5' className='centerKpi fontSizeKpi colorKpi'>Nombre</IonCol>
                        <IonCol sizeXl="8" sizeXs='7.5' className='fontSizeKpi colorKpi'>{modalSee.internData.intern_name}</IonCol>
                    </IonRow>
                    <IonRow className="kpisRow">
                        <IonCol sizeXl="4" sizeXs='4.5' className='centerKpi fontSizeKpi'>Key título</IonCol>
                        <IonCol sizeXl="8" sizeXs='7.5' className='fontSizeKpi'>{modalSee.internData.intern_title_key}</IonCol>
                    </IonRow>
                    <IonRow className="kpisRow">
                        <IonCol sizeXl="4" sizeXs='4.5' className='centerKpi fontSizeKpi colorKpi'>Campo</IonCol>
                        <IonCol sizeXl="8" sizeXs='7.5' className='fontSizeKpi colorKpi'>{modalSee.internData.intern_field}</IonCol>
                    </IonRow>
                    <IonRow className="kpisRow">
                        <IonCol sizeXl="4" sizeXs='4.5' className='centerKpi fontSizeKpi'>Type</IonCol>
                        <IonCol sizeXl="8" sizeXs='7.5' className='fontSizeKpi'>{modalSee.internData.intern_type}</IonCol>
                    </IonRow>
                    <IonRow className="kpisRow">
                        <IonCol sizeXl="4" sizeXs='4.5' className='centerKpi fontSizeKpi colorKpi'>Transform</IonCol>
                        <IonCol sizeXl="8" sizeXs='7.5' className='fontSizeKpi colorKpi'>{modalSee.internData.intern_transform.toString()}</IonCol>
                    </IonRow>
                    <IonRow className="kpisRow">
                        <IonCol sizeXl="4" sizeXs='4.5' className='centerKpi fontSizeKpi'>Endpoint</IonCol>
                        <IonCol sizeXl="8" sizeXs='7.5' className='fontSizeKpi'>{modalSee.internData.intern_endpoint_id}</IonCol>
                    </IonRow>
                    <IonRow className="kpisRow">
                        <IonCol sizeXl="4" sizeXs='4.5' className='centerKpi fontSizeKpi colorKpi'>Tiempo de recarga (min)</IonCol>
                        <IonCol sizeXl="8" sizeXs='7.5' className='fontSizeKpi colorKpi'>{modalSee.internData.intern_time_gap}</IonCol>
                    </IonRow>
                    <IonRow className="kpisRow">
                        <IonCol sizeXl="4" sizeXs='4.5' className='centerKpi fontSizeKpi'>Linechart label</IonCol>
                        <IonCol sizeXl="8" sizeXs='7.5' className='fontSizeKpi'>{modalSee.internData.intern_linechart_label}</IonCol>
                    </IonRow>
                    <IonRow className="kpisRow">
                        <IonCol sizeXl="4" sizeXs='4.5' className='centerKpi fontSizeKpi colorKpi'>Gráficos</IonCol>
                        <IonCol sizeXl="8" sizeXs='7.5' className='fontSizeKpi colorKpi'>{modalSee.internData.intern_graphic.toString()}</IonCol>
                    </IonRow>
                    {
                        modalSee.internData.intern_category.length > 0 &&
                        <>
                            <IonRow className="kpisRow">
                                <IonCol sizeXl="4" sizeXs='4.5' className='centerKpi fontSizeKpi'>Categoria</IonCol>
                                <IonCol sizeXl="8" sizeXs='7.5' className='fontSizeKpi'>{modalSee.internData.intern_category}</IonCol>
                            </IonRow>
                            <IonRow className="kpisRow">
                                <IonCol sizeXl="4" sizeXs='4.5' className='centerKpi mobile-hidden-kpis fontSizeKpi colorKpi'>Creado por</IonCol>
                                <IonCol className='mobile-hidden-kpis fontSizeKpi colorKpi' sizeXl="8" sizeXs='7.5'>{modalSee.internData.intern_created_by}</IonCol>
                            </IonRow>
                            <IonRow className="kpisRow-ultimo">
                                <IonCol sizeXl="4" sizeXs='4.5' className='centerKpi mobile-hidden-kpis fontSizeKpi'>Modificado por</IonCol>
                                {
                                    modalSee.internData.intern_updated_by.length > 0 ?
                                        <IonCol className='mobile-hidden-kpis fontSizeKpi' sizeXl="8" sizeXs='7.5'>{modalSee.internData.intern_updated_by}</IonCol> :
                                        <IonCol className='mobile-hidden-kpis fontSizeKpi' sizeXl="8" sizeXs='7.5'>--</IonCol>
                                }
                            </IonRow>:
                            
                        </>
                    }
                    {
                        !modalSee.internData.intern_category &&
                        <>
                            <IonRow className="kpisRow">
                                <IonCol sizeXl="4" sizeXs='4.5' className='centerKpi mobile-hidden-kpis fontSizeKpi'>Creado por</IonCol>
                                <IonCol className='mobile-hidden-kpis fontSizeKpi' sizeXl="8" sizeXs='7.5'>{modalSee.internData.intern_created_by}</IonCol>
                            </IonRow>
                            <IonRow className="kpisRow-ultimo">
                                <IonCol sizeXl="4" sizeXs='4.5' className='centerKpi mobile-hidden-kpis fontSizeKpi colorKpi'>Modificado por</IonCol>
                                {
                                    modalSee.internData.intern_updated_by.length > 0 ?
                                        <IonCol className='mobile-hidden-kpis fontSizeKpi colorKpi' sizeXl="8" sizeXs='7.5'>{modalSee.internData.intern_updated_by}</IonCol> :
                                        <IonCol className='mobile-hidden-kpis fontSizeKpi colorKpi' sizeXl="8" sizeXs='7.5'>--</IonCol>
                                }
                            </IonRow>

                        </>
                    }
                    <IonRow className="ion-justify-content-center">
                        <IonButton type="button" className="buttonkpi" fill="outline" onClick={() => setModalSee({ show: false, internData: modalSee.internData })}>
                            Cerrar
                        </IonButton>
                    </IonRow>
                </IonGrid>
            </IonCard>
        )
    }

    const renderTables = () => {
        if (sessionStorage.getItem("category") !== "INTERNO") {
            return (
                <IonCard>
                    <IonRow className="ion-justify-content-between ion-align-items-center">
                        <IonCol size="3" sizeXs='6'>
                            <IonTitle className="ABMKpisTitleCards">Grupos de Kpis</IonTitle>
                        </IonCol>
                        <IonCol size="1" sizeXs='2' className="ion-text-right">
                            <IonButton onClick={createKpiGroup} fill='clear' className='ABMKpisZoom'>
                                <IonIcon className="ABMKpiButton iconPosition" icon={addCircleOutline} color="secondary" size="large" />
                            </IonButton>
                        </IonCol>
                    </IonRow>

                    <IonGrid className="">
                        <IonCard className="ABMKpisCard">
                            <IonRow className="ABMKpisRow ion-align-items-center">
                                <IonCol sizeLg="3" sizeMd="3" sizeXs='4'> Nombre del Grupo </IonCol>
                                <IonCol sizeLg="2" sizeMd="2" className='mobile-hidden-kpis tablet-hidden-kpis' > Creado por </IonCol>
                                <IonCol sizeLg="2" sizeMd="3" className='mobile-hidden-kpis'> Actualizado última vez </IonCol>
                                <IonCol sizeLg="1" sizeMd='2' sizeXs='2'> Ver </IonCol>
                                <IonCol sizeLg="2" sizeMd='2' sizeXs='3.4'> Modificar </IonCol>
                                <IonCol sizeLg="2" sizeMd='2' sizeXs='2.6'> Eliminar </IonCol>
                            </IonRow>
                            {
                                kpisGroups.length > 0 && kpisGroups.map((kpiGroup: DataModify) => {
                                    return (
                                        <IonRow className="ion-align-items-center ABMKpisCol" key={kpiGroup.kpi_group_id}>
                                            <IonCol sizeLg="3" sizeMd="3" sizeXs='4'>{kpiGroup.kpi_group_name}</IonCol>
                                            <IonCol className='mobile-hidden-kpis tablet-hidden-kpis' sizeMd="2" sizeLg="2">{kpiGroup.kpi_group_created_by}</IonCol>
                                            {
                                                kpiGroup.kpi_group_updated_by.length > 0 ?
                                                    <IonCol className='mobile-hidden-kpis' sizeMd="3" sizeLg="2">{kpiGroup.kpi_group_updated_by}</IonCol> :
                                                    <IonCol className='mobile-hidden-kpis' sizeMd="3" sizeLg="2">--</IonCol>
                                            }
                                            <IonCol sizeLg="1" sizeMd="2" sizeXs='2'>
                                                <IonButton type="button" fill="clear" onClick={() => seeKpisTable(kpiGroup)} id={kpiGroup.kpi_group_name + "VER"}>
                                                    <IonIcon icon={eye} color="secondary" className="seeABMKpi ABMKpiButton" />
                                                </IonButton>
                                            </IonCol>
                                            <IonCol sizeLg="2" sizeMd="2" sizeXs='3.4'>
                                                <IonButton type="button" fill="clear" onClick={() => modifyKpiGroup(kpiGroup)} id={kpiGroup.kpi_group_name + "MODIFICAR"}>
                                                    <IonIcon icon={pencil} color="secondary" className="button-table-abmclients ABMKpiButton" />
                                                </IonButton>
                                            </IonCol>
                                            <IonCol className="colDelete" sizeLg="2" sizeMd="2" sizeXs='2.6'>
                                                <IonButton type="button" fill="clear" onClick={() => onDeleteKpisGroups({ name: kpiGroup.kpi_group_name, id: kpiGroup.kpi_group_id })} id={kpiGroup.kpi_group_name + "ELIMINAR"}>
                                                    <IonIcon icon={trash} color="danger" className="button-table-abmclients ABMKpiButton" />
                                                </IonButton>
                                            </IonCol>
                                        </IonRow>
                                    )
                                })}
                        </IonCard>
                    </IonGrid>
                </IonCard>
            )
        } else {
            return (
                <IonCard>
                    <IonRow className="ion-justify-content-between ion-align-items-center">
                        <IonCol size="3" sizeXs='9'>
                            <IonTitle className="ABMKpisTitleCards">Métricas de Internos</IonTitle>
                        </IonCol>
                        <IonCol size="1" sizeXs='3' className="ion-text-right">
                            <IonButton onClick={createInternMetric} className='ABMKpisZoom' fill='clear'>
                                <IonIcon className="iconPosition" icon={addCircleOutline} color="secondary" size="large" />
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    <IonGrid>
                        <IonCard className="ABMKpisCard">
                            <IonRow className="ABMKpisRow ion-align-items-center">

                                {
                                    sessionStorage.getItem("client") == "DECIDIR" ?
                                        <>
                                            <IonCol sizeXl="6" sizeLg='6' sizeMd='4.5' sizeXs='4.1'> Nombre de la Métrica </IonCol>
                                            <IonCol className='mobile-hidden-kpis' sizeXl="3" sizeMd='3' >Categoria</IonCol>
                                            <IonCol sizeXl="1" sizeMd='1.5' sizeSm='1' sizeXs='2'> Ver </IonCol>
                                            <IonCol sizeXl="1" sizeMd='1.5' sizeSm='1.5' sizeXs='3.3'> Modificar </IonCol>
                                            <IonCol sizeXl="1" sizeMd='1.5' sizeSm='1' sizeXs='2.6'> Eliminar </IonCol></> :
                                        <>
                                            <IonCol sizeXl="6" sizeLg='6' sizeMd='6' sizeXs='4.1'> Nombre de la Métrica </IonCol>
                                            <IonCol sizeXl="2" sizeLg='2' sizeMd='2' sizeXs='2'> Ver </IonCol>
                                            <IonCol sizeXl="2" sizeMd='2' sizeXs='3.3'> Modificar </IonCol>
                                            <IonCol sizeXl="2" sizeMd='2' sizeXs='2.6'> Eliminar </IonCol></>
                                }
                            </IonRow>
                            {internData.length > 0 &&
                                internData.map((intern: InternDataModify) => {
                                    return (
                                        <IonRow className="ion-align-items-center ABMKpisCol" key={intern.intern_id}>

                                            {
                                                sessionStorage.getItem("client") == "DECIDIR" ?
                                                    <>
                                                        <IonCol sizeXl="6" sizeMd='4.5' sizeXs='4.1'>{intern.intern_title}</IonCol>

                                                        <IonCol className='mobile-hidden-kpis' sizeXl="3" sizeMd='3'>{intern.intern_category}</IonCol>

                                                        <IonCol sizeXl="1" sizeMd='1.5' sizeSm='1' sizeXs='2'>
                                                            <IonButton type="button" fill="clear" onClick={() => setModalSee({ show: true, internData: intern })} id={intern.intern_title + "VER"}>
                                                                <IonIcon icon={eye} color="secondary" className='ABMKpiButton'/>
                                                            </IonButton>
                                                        </IonCol>

                                                        <IonCol sizeXl="1" sizeMd='1.5' sizeSm='1.5' sizeXs='3.3'>
                                                            <IonButton type="button" fill="clear" onClick={() => modifyInternMetric(intern)} id={intern.intern_name + "MODIFICAR"}>
                                                                <IonIcon icon={pencil} color="secondary" className="button-table-abmclients ABMKpiButton" />
                                                            </IonButton>
                                                        </IonCol>

                                                        <IonCol sizeXl="1" sizeMd='1.5' sizeSm='1.5' sizeXs='2.6' className="colDelete">
                                                            <IonButton type="button" fill="clear" onClick={() => onDeleteInternMetric({ name: intern.intern_title, id: intern.intern_id })} id={intern.intern_name + "ELIMINAR"}>
                                                                <IonIcon icon={trash} color="danger" className="button-table-abmclients ABMKpiButton" />
                                                            </IonButton>
                                                        </IonCol>

                                                    </> :

                                                    <>
                                                        <IonCol sizeXl="6" sizeMd='6' sizeXs='4.1'>{intern.intern_title}</IonCol>
                                                        <IonCol size="2" sizeMd='2' sizeSm='2' sizeXs='2'>
                                                            <IonButton type="button" fill="clear" onClick={() => setModalSee({ show: true, internData: intern })} id={intern.intern_title + "VER"}>
                                                                <IonIcon icon={eye} color="secondary" className='ABMKpiButton'/>
                                                            </IonButton>
                                                        </IonCol>
                                                        <IonCol size="2" sizeMd='2' sizeSm='2.5' sizeXs='3.3'>
                                                            <IonButton type="button" fill="clear" onClick={() => modifyInternMetric(intern)} id={intern.intern_name + "MODIFICAR"}>
                                                                <IonIcon icon={pencil} color="secondary" className="button-table-abmclients ABMKpiButton" />
                                                            </IonButton>
                                                        </IonCol>
                                                        <IonCol sizeXl="2" sizeMd='2' sizeSm='2.5' sizeXs='2.6' className="colDelete">
                                                            <IonButton type="button" fill="clear" onClick={() => onDeleteInternMetric({ name: intern.intern_title, id: intern.intern_id })} id={intern.intern_name + "ELIMINAR"}>
                                                                <IonIcon icon={trash} color="danger" className="button-table-abmclients ABMKpiButton" />
                                                            </IonButton>
                                                        </IonCol>
                                                    </>
                                            }
                                        </IonRow>
                                    )
                                })
                            }
                        </IonCard>
                    </IonGrid>

                    <Modal
                        modalChildren={
                            {
                                isOpen: modalSee.show,
                                onDidDismiss: () => setModalSee({ show: false, internData: modalSee.internData }),
                                id: 'modal-See-Kpi',
                                styles: 'kpis-modal',
                                children: returnChildrenModal()
                            }
                        }
                    />
                </IonCard>
            )
        }
    }

    return (
        <IonPage>
            <Header headerText="ABM Métricas" />
            {
                renderViews.renderKpiGroupsCreate ? <AddGroupABMKpis backToTable={backToKpisGroupTable} seeKpis={seeKpisTable} /> :
                    renderViews.renderInternCreate ?
                        <AddABMIntern backToTable={backToKpisGroupTable
                        } internData={internData} /> :
                        renderViews.renderKpiGroupsModify ? <ModifyGroupsABMKpis kpiGroupData={dataToModify} backToTable={backToKpisGroupTable} /> :
                            renderViews.renderInternModify ?
                                <ModifyABMIntern backToTable={backToKpisGroupTable} internData={dataInternToModify} service_name={clientSelect} /> :
                                renderViews.renderKpisTable ? <SeeABMKpis kpiGroupData={dataToModify} backToKpiGroupTable={backToKpisGroupTable} /> :
                                    renderViews.renderKpiGroupsTable ?
                                        <IonContent fullscreen>
                                            <IonGrid className="grid-metrics" id="ion-content-main">
                                                <IonCard className='ABMKpisClient'>
                                                    <IonRow className="ion-align-items-center">
                                                        <IonButton title="Seleccionar cliente" fill="clear" className="header-button padding-04" onClick={() => showModalFunc()}>
                                                            <IonIcon icon={peopleCircle} className="ABMKpis-icon-select-client" />
                                                        </IonButton>
                                                        <IonText>
                                                            <h5 className="ABMKpis-title-select-client ion-no-margin">
                                                                {sessionStorage.getItem("client")}
                                                            </h5>
                                                        </IonText>
                                                    </IonRow>
                                                </IonCard>
                                                {
                                                    renderTables()
                                                }
                                            </IonGrid>

                                            <Modal
                                                modalPrincipal={
                                                    {
                                                        textPrincipal: `¿Desea Eliminar el Grupo de Kpis ${dataForDelete.name}?`,
                                                        isOpen: showModalRemove,
                                                        onDidDismiss: () => setShowModalRemove(false),
                                                        onClickCloseModal: () => setShowModalRemove(false),
                                                        onClick: deleteKpisGroups,
                                                        nameIcon: "warning",
                                                        colorIcon: "danger",
                                                    }
                                                }
                                                modalConfirmation={
                                                    {
                                                        textConfirmation: "Grupo de Kpis Eliminado Correctamente",
                                                        onDidDimissConfirmModal: onModalDismiss,
                                                        isOpenModalConfirm: showModalExit,
                                                        onClickConfirmModal: onModalDismiss,

                                                    }
                                                }
                                            />

                                            {showSipnner &&
                                                <IonCol size="12" className="ion-padding">
                                                    <IonSpinner color="tertiary" name="lines" style={{ position: "fixed", top: "50%", left: "50%" }} />
                                                </IonCol>
                                            }

                                            <Modal
                                                modalPrincipal={{
                                                    textPrincipal: `¿Desea Eliminar la Métrica '${dataForDelete.name}'?`,
                                                    isOpen: showModalRemoveIntern,
                                                    onDidDismiss: () => setShowModalRemoveIntern(false),
                                                    onClickCloseModal: () => setShowModalRemoveIntern(false),
                                                    onClick: deleteInternMetric,
                                                    nameIcon: "warning",
                                                    colorIcon: "danger",
                                                }}
                                                modalConfirmation={
                                                    {
                                                        textConfirmation: "Métrica Eliminada Correctamente",
                                                        onDidDimissConfirmModal: onModalDismissIntern,
                                                        isOpenModalConfirm: showModalExitIntern,
                                                        onClickConfirmModal: onModalDismissIntern,
                                                    }
                                                }
                                            />

                                            {showSipnnerIntern &&
                                                <IonCol size="12" className="ion-padding">
                                                    <IonSpinner color="tertiary" name="lines" style={{ position: "fixed", top: "50%", left: "50%" }} />
                                                </IonCol>
                                            }
                                            <SwtListClientsModal parentCallback={selectClient} showModal={showModal} closeModalCallback={closeModalFunc} />
                                        </IonContent>
                                        : <Loading />
            }
            <Footer />
        </IonPage>
    )

}

//Redux state and actions dispatchers
interface StateTypes {
    ABMKpisGroups: ABMKpisGroupsDefaultStateI;
    ABMIntern: ABMInternDefaultStateI;
}

const mapStatetoProps = (state: StateTypes) => {
    return {
        ABMKpisGroups: state.ABMKpisGroups,
        ABMIntern: state.ABMIntern,
    }
}

interface dispatchTypes {
    getABMKpisGroups: () => void;
    getABMIntern: () => void;
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getABMKpisGroups: () => dispatch(GetABMKpisGroups()),
        getABMIntern: () => dispatch(GetABMIntern())
    };
}

export default connect(mapStatetoProps, mapDispatchToProps)(ABMMetricMainView);