import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ApiRequestor from '../../api_access/ApiRequestor';
import { IonButton, IonCard, IonCardTitle, IonCol, IonContent, IonGrid, IonIcon, IonInput, IonItem, IonLabel, IonRow, IonText } from '@ionic/react';
import { checkmarkCircleOutline, closeCircleOutline, helpCircleOutline } from 'ionicons/icons';

import { GetABMUsers } from '../../store/ABMUsers/ABMUsersActions';
import { ClientMaintenanceDefaultStateI } from '../../store/mainInfo/clientMaintenanceReducer';

import './ABMUsers.css';
import Modal from '../Modal/Modal';

interface Props extends StateTypes, dispatchTypes {
    client?: any;
    backToTable: () => void;
}

const AddABMUsers: React.FC<Props> = (props): JSX.Element => {

    const regularExpressionEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const regularExpressionPass = /^(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9]{8,}$/;
    const regularExpressionPhone = /^\+(?=.*549)\d{13}$/;
    const [showModal, setShowModal] = useState(false);
    const [textModal, setTextModal] = useState('')
    const [dataForm, saveDataForm] = useState({
        username: '',
        password: '',
        passwordRep: '',
        first_name: '',
        last_name: '',
        phone_number: '',
        category: '',
        email: '',
        emailRep: ''
    });
    const [usernameInvalid, setUsernameInvalid] = useState(false);
    const [nameInvalid, setNameInvalid] = useState(false);
    const [lastnameInvalid, setLastnameInvalid] = useState(false);
    const [passwordInvalid, setPasswordInvalid] = useState(false);
    const [passwordRepInvalid, setPasswordRepInvalid] = useState(false);
    const [phoneInvalid, setPhoneInvalid] = useState(false);
    const [emailInvalid, setEmailInvalid] = useState(false);
    const [emailRepInvalid, setEmailRepInvalid] = useState(false);
    const [categoryClient, setCategoryClient] = useState('')
    const { username, password, passwordRep, first_name, last_name, phone_number, category, email, emailRep } = dataForm;

    useEffect(() => {
        if (props.clientMaintenance.loading == false && props.clientMaintenance.clientMaintenance) {
            const filterClient = props.clientMaintenance.clientMaintenance.filter((client: any) => client.service_name == props.client)
            const category = filterClient[0].service_category
            setCategoryClient(category)
        }
    }, [props.clientMaintenance.loading, props.clientMaintenance.clientMaintenance])

    const getDataForm = (e: any) => {
        saveDataForm({
            ...dataForm,
            [e.target.name]: e.detail.value!
        })
    }

    const validationForm = () => {
        Object.keys(dataForm).forEach((key) => {
            if ((dataForm as any)[key].length < 1) {
                key == 'username' && setUsernameInvalid(true)
                key == 'first_name' && setNameInvalid(true)
                key == 'last_name' && setLastnameInvalid(true)
            } else {
                key == 'username' && setUsernameInvalid(false)
                key == 'first_name' && setNameInvalid(false)
                key == 'last_name' && setLastnameInvalid(false)
            }
            if (key == 'email') {
                !regularExpressionEmail.test(dataForm.email) ? setEmailInvalid(true) : setEmailInvalid(false)
            }
            if (key == 'emailRep') {
                dataForm.email !== dataForm.emailRep ? setEmailRepInvalid(true) : setEmailRepInvalid(false)
            }
            if (key == 'password') {
                !regularExpressionPass.test(dataForm.password) ? setPasswordInvalid(true) : setPasswordInvalid(false)
            }
            if (key == 'passwordRep') {
                dataForm.password !== dataForm.passwordRep ? setPasswordRepInvalid(true) : setPasswordRepInvalid(false)
            }
            if (key == 'phone_number') {
                !regularExpressionPhone.test(dataForm.phone_number) ? setPhoneInvalid(true) : setPhoneInvalid(false)
            }
        })
    }

    const createNewUser = async (e: any) => {
        e.preventDefault();
        validationForm()

        let valid = false;

        if (dataForm.first_name.length > 1 && dataForm.last_name.length > 1 && dataForm.username.length > 1 &&
            regularExpressionEmail.test(dataForm.email) && regularExpressionPass.test(dataForm.password)
            && regularExpressionPhone.test(dataForm.phone_number) && dataForm.email == dataForm.emailRep
            && dataForm.password == dataForm.passwordRep) {
            valid = true
        }

        if (valid) {
            let data: any = {
                body: {
                    username: dataForm.username,
                    password: dataForm.password,
                    bank_id: props.client,
                    first_name: dataForm.first_name,
                    last_name: dataForm.last_name,
                    phone_number: dataForm.phone_number,
                    email: dataForm.email,
                    category: categoryClient,
                }
            }

            const response: any = await ApiRequestor.requestToApi("/user", "POST", data, "USER");

            if (response && response.status === 200) {
                setTextModal('Usuario Creado Correctamente')
                setShowModal(true);
            } else {
                if (response && response.includes("status code 500")) {
                    setTextModal('No se pudo crear el usuario. Por favor chequear los datos ingresados')
                    setShowModal(true)
                }
            }
        } else {
            validationForm()
        }
    }

    const dismissModal = () => {
        setShowModal(false);
        if (textModal.includes('Usuario Creado Correctamente')) {
            props.getABMUsers()
            props.backToTable()
        }
    }

    const onKeyPressLetters = (e: any) => {
        const re = /[A-ZÑñ ]+/g;
        if (re.test(e.key)) {
            e.preventDefault();
        }
    }

    return (
        <IonContent fullscreen className="ion-padding">
            <IonCard className="ABMUsersFormCard">
                <IonCardTitle className="ion-text-center ABMUsersFormTitle">
                    ALTA DE USUARIO
                </IonCardTitle>
                <IonGrid className="ion-margin ion-no-margin-top">
                    <IonRow>
                        <IonCol className="ABMUsersFormCol" sizeLg='6' sizeXs='12'>
                            <IonLabel className="ion-margin-vertical ABMUsersFormLabel">
                                Nombre
                            </IonLabel>
                            <IonItem className="ABMUsersFormItem" lines='none'>
                                <IonInput
                                    type="text"
                                    placeholder="Nombre"
                                    className="ion-padding ABMUsersFormInput"
                                    name="first_name"
                                    autocomplete="new-password"
                                    value={first_name}
                                    onIonChange={(e) => {
                                        getDataForm(e)
                                    }}>
                                </IonInput>
                            </IonItem>
                            <IonText>
                                <p className={nameInvalid ? "login-valid-text" : "ion-hide"}>Longitud menor a 2 caracteres</p>
                            </IonText>
                        </IonCol>
                        <IonCol sizeLg='6' sizeXs='12' className="ABMUsersFormCol">
                            <IonLabel className="ion-margin-vertical ABMUsersFormLabel">
                                Apellido
                            </IonLabel>
                            <IonItem className="ABMUsersFormItem" lines='none'>
                                <IonInput
                                    type="text"
                                    placeholder="Apellido"
                                    className="ion-padding ABMUsersFormInput"
                                    id="input-id-add"
                                    name="last_name"
                                    autocomplete="new-password"
                                    value={last_name}
                                    onIonChange={(e) => {
                                        getDataForm(e)
                                    }}>
                                </IonInput>
                            </IonItem>
                            <IonText>
                                <p className={lastnameInvalid ? "login-valid-text" : "ion-hide"}>Longitud menor a 2 caracteres</p>
                            </IonText>
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol sizeLg='6' sizeXs='12' className="ABMUsersFormCol">
                            <IonLabel className="ion-margin-vertical ABMUsersFormLabel ABMUsersTooltipLabel">
                                Username
                                <IonIcon icon={helpCircleOutline} className="ABMUsersTooltip" />
                                <IonText className="ABMUsersTooltipText">No acepta mayúsculas</IonText>
                            </IonLabel>
                            <IonItem className="ABMUsersFormItem" lines='none'>
                                <IonInput
                                    type="text"
                                    placeholder="Username"
                                    className="ion-padding ABMUsersFormInput"
                                    id="input-id-add"
                                    name="username"
                                    autocomplete="new-password"
                                    value={username}
                                    onIonChange={(e) => {
                                        getDataForm(e)
                                    }}
                                    onKeyPress={(e) => onKeyPressLetters(e)} />
                            </IonItem>
                            <IonText>
                                <p className={usernameInvalid ? "login-valid-text" : "ion-hide"}>Longitud menor a 2 caracteres</p>
                            </IonText>
                        </IonCol>
                        <IonCol className="ABMUsersFormCol" sizeLg='6' sizeXs='12'>
                            <IonLabel className="ion-margin-vertical ABMUsersFormLabel">
                                Cliente
                            </IonLabel>
                            <IonItem className="ABMUsersFormItem" lines='none'>
                                <IonInput
                                    type="text"
                                    placeholder="Selecciona un cliente"
                                    className="ion-padding ABMUsersFormInput"
                                    name="bank_id"
                                    value={props.client}
                                    disabled />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol sizeLg='6' sizeXs='12' className="ABMUsersFormCol">
                            <IonLabel className="ion-margin-vertical ABMUsersFormLabel ABMUsersTooltipLabel">
                                Email
                                <IonIcon icon={helpCircleOutline} className="ABMUsersTooltip" />
                                <IonText className="ABMUsersTooltipText">No acepta mayúsculas</IonText>
                            </IonLabel>
                            <IonItem className="ABMUsersFormItem" lines='none'>
                                <IonInput
                                    type="text"
                                    placeholder="Email"
                                    className="ion-padding ABMUsersFormInput"
                                    id="input-id-add"
                                    name="email"
                                    autocomplete="new-password"
                                    value={email}
                                    onIonChange={(e) => getDataForm(e)}
                                    onKeyPress={(e) => onKeyPressLetters(e)} />
                            </IonItem>
                            <IonText>
                                <p className={emailInvalid ? "login-valid-text" : "ion-hide"}>Debe ingresar un email válido</p>
                            </IonText>
                        </IonCol>
                        <IonCol className="ABMUsersFormCol" sizeLg='6' sizeXs='12'>
                            <IonLabel className="ion-margin-vertical ABMUsersFormLabel">
                                Reescriba el email
                            </IonLabel>
                            <IonItem className="ABMUsersFormItem" lines='none'>
                                <IonInput
                                    type="text"
                                    placeholder="Reescriba el email"
                                    className="ion-padding ABMUsersFormInput"
                                    name="emailRep"
                                    autocomplete="new-password"
                                    value={emailRep}
                                    onIonChange={(e) => getDataForm(e)} />
                            </IonItem>
                            <IonText>
                                <p className={emailRepInvalid ? "login-valid-text" : "ion-hide"}>Los email no coinciden</p>
                            </IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className="ABMUsersFormCol" sizeLg='6' sizeXs='12'>
                            <IonLabel className="ion-margin-vertical ABMUsersFormLabel">
                                Número De Teléfono
                            </IonLabel>
                            <IonItem className="ABMUsersFormItem" lines='none'>
                                <IonInput
                                    type="text"
                                    placeholder="+549XXXXXXXXXX"
                                    className="ion-padding ABMUsersFormInput"
                                    value={phone_number}
                                    name="phone_number"
                                    autocomplete="new-password"
                                    onIonChange={(e) => getDataForm(e)} />
                            </IonItem>
                            <IonText>
                                <p className={phoneInvalid ? "login-valid-text" : "ion-hide"}>Debe ser de la forma +549 más 10 dígitos</p>
                            </IonText>
                        </IonCol>
                        <IonCol className="ABMUsersFormCol" sizeLg='6' sizeXs='12'>
                            <IonLabel className="ion-margin-vertical ABMUsersFormLabel">
                                Categoría
                            </IonLabel>
                            <IonItem className="ABMUsersFormItem" lines='none'>
                                <IonInput
                                    type="text"
                                    placeholder="Selecciona una Categoría"
                                    className="ion-padding ABMUsersFormInput"
                                    name="bank_id_category"
                                    value={categoryClient}
                                    disabled />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className="ABMUsersFormCol" sizeLg='6' sizeXs='12'>
                            <IonLabel className="ion-margin-vertical ABMUsersFormLabel">
                                Contraseña provisioria
                            </IonLabel>
                            <IonItem className="ABMUsersFormItem" lines='none'>
                                <IonInput
                                    type="password"
                                    placeholder="Mínimo 8 caracteres"
                                    className="ion-padding ABMUsersFormInput"
                                    name="password"
                                    autocomplete="new-password"
                                    value={password}
                                    onIonChange={(e) => getDataForm(e)} />
                            </IonItem>
                            <IonText>
                                <p className={passwordInvalid ? "login-valid-text" : "ion-hide"}>Debe tener al menos 8 caracteres una letra mayúscula y una minúscula</p>
                            </IonText>
                        </IonCol>
                        <IonCol className="ABMUsersFormCol" sizeLg='6' sizeXs='12'>
                            <IonLabel className="ion-margin-vertical ABMUsersFormLabel">
                                Confirmar Contraseña Provisioria
                            </IonLabel>
                            <IonItem className="ABMUsersFormItem" lines='none'>
                                <IonInput
                                    type="password"
                                    placeholder="Mínimo 8 caracteres"
                                    className="ion-padding ABMUsersFormInput"
                                    name='passwordRep'
                                    autocomplete="new-password"
                                    value={passwordRep}
                                    onIonChange={(e) => getDataForm(e)} />
                            </IonItem>
                            <IonText>
                                <p className={passwordRepInvalid ? "login-valid-text" : "ion-hide"}>Las contraseñas no coinciden</p>
                            </IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ABMUsers-container-button  ion-margin-horizontal ion-margin-top ion-no-margin-bottom ion-justify-content-center">
                        <IonButton type="submit" fill="clear" onClick={createNewUser}>
                            <IonIcon icon={checkmarkCircleOutline} color="success" className="ion-padding ion-no-padding-bottom AMBUsersButton" />
                        </IonButton>
                        <IonButton fill="clear" onClick={props.backToTable}>
                            <IonIcon icon={closeCircleOutline} color="danger" className="ion-padding ion-no-padding-bottom AMBUsersButton" />
                        </IonButton>
                    </IonRow>
                </IonGrid>
            </IonCard>

            <Modal

                modalConfirmation={{
                    textConfirmation: textModal,
                    onDidDimissConfirmModal: dismissModal,
                    isOpenModalConfirm: showModal,
                    onClickConfirmModal: dismissModal,
                }}
            />
        </IonContent>
    )
}

interface StateTypes {
    clientMaintenance: ClientMaintenanceDefaultStateI
}

const mapStateProps = (state: StateTypes) => {
    return {
        clientMaintenance: state.clientMaintenance
    }
}

interface dispatchTypes {
    getABMUsers: () => void;
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getABMUsers: () => dispatch(GetABMUsers())
    };
}

export default connect(mapStateProps, mapDispatchToProps)(AddABMUsers);