import React, { useState } from 'react';
import { connect } from 'react-redux';
import { IonButton, IonCol, IonIcon, IonRow, IonSpinner, IonText } from '@ionic/react';
import { checkmarkCircleOutline, closeCircleOutline, pencil, trash } from 'ionicons/icons';

import { GetClientNotifications } from '../../store/clientNotifications/clientNotificationsActions';
import { DateTime } from "luxon";

import ApiRequestor from '../../api_access/ApiRequestor';

import './ABMPrismaMessages.css';
import Modal from '../Modal/Modal';

interface Message {
    message_swt_id: number;
    message_swt_message: string;
    message_swt_services: string[];
    message_swt_date: string;
    message_swt_close: boolean
}

interface Props extends StateTypes, dispatchTypes {
    messagesData: Message
    renderModify: (messageData: Message) => void
}

const TableABMPrismaMessages: React.FC<Props> = (props): JSX.Element => {
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [showModalSuccessDelete, setShowModalSuccessDelete] = useState(false);
    const [showSipnner, setShowSpinner] = useState(false)

    const deleteMessage = async (id: any) => {
        setShowModalDelete(false);
        let data: any = {
            params: [
                id
            ]
        }
        const response = await ApiRequestor.requestToApi(`/swt-admin/swt-messages`, "DELETE", data);
        if (response && response.status == 200) {
            setShowSpinner(false)
            setShowModalSuccessDelete(true)
        }
    }

    const deleteClientModal = () => {
        setShowModalSuccessDelete(false);
        props.getClientNotifications()
    }

    return (
        <IonRow className="ion-align-items-center ion-justify-content-center">
            <IonCol className="ABMPrismaMessagesCol" sizeLg="2.5">
                {
                    props.messagesData.message_swt_services.map((client: string, i: any, arr: any) => {
                        return (
                            (arr.length - 1 == i) ?
                                <>
                                    <IonText className='mobile-text'>
                                        Cliente
                                    </IonText>
                                    <IonText>
                                        {`${client} `}
                                    </IonText>
                                </>
                                :
                                <>
                                    <IonText className='mobile-text'>
                                        Clientes
                                    </IonText>
                                    <IonText>
                                        {`${client}, `}
                                    </IonText>
                                </>
                        )
                    })
                }
            </IonCol>
            <IonCol className="ABMPrismaMessagesCol" sizeLg="4" sizeXs='12'>
                <IonText className='mobile-text'>
                    Mensaje
                </IonText>
                <IonText>
                    {props.messagesData.message_swt_message}
                </IonText>
            </IonCol>
            <IonCol className="ABMPrismaMessagesCol" sizeLg="2.5" sizeXs='12'>
                <IonText className='mobile-text'>
                    Expiración
                </IonText>
                <IonText>
                    {DateTime.fromISO(props.messagesData.message_swt_date).toFormat('dd-MM-yyyy HH:mm')}
                </IonText>
            </IonCol>
            <IonCol className="ABMPrismaMessagesCol" sizeLg="1.5" sizeXs='6'>
                <IonText className='mobile-text'>
                    Modificar
                </IonText>
                <IonButton type="button" fill="clear" onClick={() => props.renderModify(props.messagesData)} id={props.messagesData.message_swt_services + "-Modify"}>
                    <IonIcon icon={pencil} color="secondary" className="ABMPrismaMessagesTableButton" />
                </IonButton>
            </IonCol>
            <IonCol className="ABMPrismaMessagesCol" sizeLg="1.5" sizeXs='6'>
                <IonText className='mobile-text'>
                    Eliminar
                </IonText>
                <IonButton type="button" fill="clear" className="" onClick={() => setShowModalDelete(true)} id={props.messagesData.message_swt_services + "-Remove"}>
                    <IonIcon icon={trash} color="danger" className="ABMPrismaMessagesTableButton" />
                </IonButton>
            </IonCol>

            <Modal
                modalPrincipal={
                    {
                        textPrincipal: `¿Deseas eliminar el Mensaje ${props.messagesData.message_swt_message}?`,
                        isOpen: showModalDelete,
                        onDidDismiss: () => setShowModalDelete(false),
                        onClick: () => deleteMessage(props.messagesData.message_swt_id),
                        onClickCloseModal: () => setShowModalDelete(false),
                        nameIcon: "warning",
                        colorIcon: "danger",

                    }
                }
                modalConfirmation={
                    {
                        isOpenModalConfirm: showModalSuccessDelete,
                        onDidDimissConfirmModal: () => deleteClientModal(),
                        textConfirmation: "Mensaje Eliminado con Exito",
                        onClickConfirmModal: () => setShowModalSuccessDelete(false)

                    }
                }


            />

            {
                showSipnner &&
                <IonCol size="12" className="ion-padding">
                    <IonSpinner color="tertiary" name="lines" style={{ position: "fixed", top: "50%", left: "50%" }} />
                </IonCol>
            }
        </IonRow>
    )
}

//Redux state and actions dispatchers
interface StateTypes {
}

const mapStateToProps = (state: StateTypes) => {
    return {
    };
};

interface dispatchTypes {
    getClientNotifications: () => void
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getClientNotifications: () => dispatch(GetClientNotifications())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableABMPrismaMessages);