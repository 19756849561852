import React from "react";
import { connect } from "react-redux";

import { SessionDefaultStateI } from "../../store/session/SessionReducer";

import "./footer.css";

interface Props extends StateTypes { }

const Footer: React.FC<Props> = (props): JSX.Element => {
  let agno = new Date().getFullYear();
  const text = "Copyright © Prisma Medios de Pago S.A. " +  agno + " IT & Operation";

  return (
    <div className={props.session.loggedIn ? "footer" : "footer footerLogin"}>
      <p className="footerText">{`${text}`}</p>
    </div>
  );
};

interface StateTypes {
  session: SessionDefaultStateI;
}

const mapStateToProps = (state: StateTypes) => {
  return {
    session: state.session,
  };
};

export default connect(mapStateToProps)(Footer);
