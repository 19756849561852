import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    IonButton, IonCard, IonCardHeader, IonCardTitle, IonCol, IonContent, IonGrid, IonIcon, IonInput, IonItem, IonLabel,
    IonRow, IonText, IonTitle
} from '@ionic/react';
import { checkmarkCircleOutline, closeCircleOutline } from 'ionicons/icons';

import { ABMClientsDefaultStateI } from '../../store/ABMClients/ABMClientsReducer';
import { GetABMClients } from '../../store/ABMClients/ABMClientsActions';
import { GetABMKpisGroups } from '../../store/ABMMetric/ABMKpisGroups/ABMKpisGroupsActions';

import ApiRequestor from '../../api_access/ApiRequestor';

import './ABMMetric.css';
import Modal from '../Modal/Modal';

type KpiGroupData = {
    client: string;
    name: string;
}

interface Props extends StateTypes, dispatchTypes {
    backToTable: () => void
    seeKpis: (kpiGroup: any) => void
}

const AddGroupsABMKpis: React.FC<Props> = (props): JSX.Element => {

    const [kpisGroupData, setKpisGroupData] = useState<KpiGroupData>({
        client: '',
        name: ''
    })
    const [clientInvalid, setClientInvalid] = useState(false)
    const [internId, setInternId] = useState();
    const [nameInvalid, setNameInvalid] = useState(false)
    const [showModalAddGroups, setShowModalAddGroups] = useState(false);

    useEffect(() => {
        props.getClients()
    }, [])

    useEffect(() => {
        if (props.ABMClients.loading === false && props.ABMClients.abmclients) {
            props.ABMClients.abmclients.map((client: any) => {
                if (sessionStorage.getItem("client") == client.service_name) {
                    setInternId(client.service_id)
                }
            })
        }
    }, [sessionStorage.getItem("client"), props.ABMClients.loading, props.ABMClients.abmclients])

    const saveData = (e: any) => {
        setKpisGroupData({
            ...kpisGroupData,
            [e.target.name]: e.detail.value!
        })
    }

    const validateForm = () => {
        if (!internId) {
            setClientInvalid(true)
        } else {
            setClientInvalid(false)
        }
        if (kpisGroupData.name.length < 1) {
            setNameInvalid(true)
        } else {
            setNameInvalid(false)
        }
    }

    const createKpisGroup = async () => {
        validateForm()
        let valid = false
        if (internId && kpisGroupData.name.length > 0) {
            valid = true
        }
        if (valid) {
            let data: any = {
                body: {
                    service_id: internId,
                    kpi_group_name: kpisGroupData.name
                }
            }

            const response = await ApiRequestor.requestToApi('/swt-admin/kpi-groups', 'POST', data)
            if (response && response.status == 200) {
                setShowModalAddGroups(true)
            }
        } else {
            validateForm()
        }
    }

    const onModalDismiss = () => {
        setShowModalAddGroups(false)
        props.getABMKpisGroups()
        const data: any = {
            service_id: internId,
            kpi_group_name: kpisGroupData.name
        }
        props.seeKpis(data)
    }

    return (
        <IonContent fullscreen >
            <IonCard className="ABMKpisCardFormAdd">
                <IonCardHeader>
                    <IonTitle className="ABMKpisTitleKpi">ALTA GRUPO DE KPIS</IonTitle>

                </IonCardHeader>
                <IonGrid className='ABMKpisADD'>
                    <IonRow>
                        <IonCol>
                            <IonLabel className="ABMKpisLabelKpis">Cliente</IonLabel>
                            <IonItem lines='none'>
                                <IonInput
                                    placeholder="Seleccione el Cliente"
                                    className="ABMKpisSelectKpis"
                                    name='client'
                                    value={sessionStorage.getItem("client")}
                                    disabled>
                                </IonInput>
                            </IonItem>
                            <IonText><p className={clientInvalid ? "login-valid-text" : "ion-hide"}> Debe seleccionar un cliente</p></IonText>
                        </IonCol>
                        <IonCol sizeXl="6" sizeXs='12'>
                            <IonLabel className="ABMKpisLabelKpis">Nombre del Grupo de Kpis</IonLabel>
                            <IonItem lines='none'>
                                <IonInput
                                    className="ABMKpisInputKpis"
                                    type="text"
                                    placeholder="Nombre grupo Kpis"
                                    name='name'
                                    value={kpisGroupData.name}
                                    onIonChange={(e) => saveData(e)} />
                            </IonItem>
                            <IonText><p className={nameInvalid ? "login-valid-text" : "ion-hide"}>Longitud menor a 2 caracteres</p></IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center containerButtonKpis">
                        <IonButton className="ClassButtonKpis ABMKpisZoom" type="submit" fill="clear" onClick={createKpisGroup}>
                            <IonIcon icon={checkmarkCircleOutline} color="success" className="ABMKpisButton" />
                        </IonButton>
                        <IonButton className="ClassButtonKpis ABMKpisZoom" fill="clear" onClick={props.backToTable}>
                            <IonIcon icon={closeCircleOutline} color="danger" className="ABMKpisButton" />
                        </IonButton>
                    </IonRow>
                </IonGrid>
            </IonCard>


            <Modal
                modalConfirmation={{
                    textConfirmation: "Grupo de Kpis Creado Correctamente",
                    onDidDimissConfirmModal: onModalDismiss,
                    isOpenModalConfirm: showModalAddGroups,
                    onClickConfirmModal: onModalDismiss,
                }}
            />

        </IonContent>
    )
}

//Redux state and actions dispatchers
interface StateTypes {
    ABMClients: ABMClientsDefaultStateI
}

const mapStatetoProps = (state: StateTypes) => {
    return {
        ABMClients: state.ABMClients
    }
}

interface dispatchTypes {
    getClients: () => void;
    getABMKpisGroups: () => void;
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getClients: () => dispatch(GetABMClients()),
        getABMKpisGroups: () => dispatch(GetABMKpisGroups())
    };
}

export default connect(mapStatetoProps, mapDispatchToProps)(AddGroupsABMKpis);